/**
 * @file CallSessionSection1.tsx
 * @description
 */
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Stack,
    SxProps,
    Tooltip,
    Typography,
} from '@mui/material'
import {
    CallStatusEnum,
    CallStatusOptionEnum,
    useCallSession,
} from 'context/CallSessionProvider'
import React, { useEffect, useState } from 'react'
import { ActionSection } from './ActionSection'
import { CallSessionRadioGroup } from './CallSessionRadioGroup'
import { OpportunityForm } from './OpportunityForm'
import { SessionOutcome } from './SessionOutcome'
import formatters from 'utils/formatters'

type CallSessionSection1Props = {
    sx?: SxProps
}

export const CallSessionSection1: React.FC<CallSessionSection1Props> = ({
    sx,
}) => {
    const [expanded, setExpanded] = useState<boolean>(false)
    const [unsuccessfulDate, setUnsuccessfulDate] = useState<string>('')
    const [buttonClicked, setButtonClicked] = useState<boolean>(false) // new state

    const {
        saveCallSession,
        account,
        isSaving,
        callSessionV2,
        saveSessionDisabled,
    } = useCallSession()

    const handleSaveSession = () => {
        setButtonClicked(true)
        saveCallSession().finally(() => setButtonClicked(false)) // reset button state after save completes
    }

    useEffect(() => {
        if (account?.last_unsuccessful_attempt) {
            const date = formatters.formatDate(
                account?.last_unsuccessful_attempt,
                'utc',
                'America/Chicago'
            ).localDate
            setUnsuccessfulDate(date ?? 'N/A')
            return
        }
        setUnsuccessfulDate('N/A')
    }, [account?.last_unsuccessful_attempt])

    return (
        <Box
            sx={{
                minHeight: '56px',
                background: 'rgba(255, 255, 255, 1)',
                paddingX: 2,
                boxShadow:
                    '0px 2px 4px -2px rgba(0,0,0,0.1), 0px 2px 2px -2px rgba(0,0,0,0.06)',
                ...sx,
            }}
        >
            <Box
                paddingY={1}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <ActionSection expand={expanded} setExpand={setExpanded} />
                <Stack direction="row" gap={2} alignItems="center">
                    <Divider flexItem orientation="vertical" />
                    <Stack direction="column" gap={0.5}>
                        <Typography
                            color="#727492"
                            fontFamily="Inter"
                            fontWeight={500}
                            fontSize={11}
                            sx={{
                                '& .bold-letter': {
                                    color: 'black',
                                },
                            }}
                        >
                            Unsuccessful Attempts:
                        </Typography>
                        <Typography
                            color="#727492"
                            fontFamily="Inter"
                            fontWeight={500}
                            fontSize={11}
                            sx={{
                                '& .bold-char': {
                                    color: 'rgba(0, 0, 33, 1)',
                                    fontWeight: 600,
                                    fontSize: 12,
                                },
                            }}
                        >
                            Total:{' '}
                            <span className="bold-char">
                                {account?.unsuccessful_attempts ?? 'N/A'}
                            </span>{' '}
                            Last:{' '}
                            <span className="bold-char">
                                {unsuccessfulDate ?? 'N/A'}
                            </span>
                        </Typography>
                    </Stack>
                    <Divider flexItem orientation="vertical" />
                    <Tooltip
                        title={
                            saveSessionDisabled
                                ? 'Cannot save call session for Contacts with  Closed or Rejected status.'
                                : ''
                        }
                    >
                        <div>
                            <Button
                                variant="contained"
                                disableFocusRipple
                                disableRipple
                                onClick={
                                    !buttonClicked && !saveSessionDisabled
                                        ? handleSaveSession
                                        : undefined
                                }
                                disabled={
                                    buttonClicked ||
                                    saveSessionDisabled ||
                                    isSaving
                                }
                                startIcon={
                                    isSaving && (
                                        <CircularProgress
                                            size={15}
                                            sx={{
                                                color: 'white !important',
                                            }}
                                        />
                                    )
                                }
                                sx={{
                                    borderRadius: '100px',
                                    background:
                                        buttonClicked ||
                                        saveSessionDisabled ||
                                        isSaving
                                            ? ''
                                            : 'linear-gradient(0deg, #1898FF 0%, #784DFF 69.91%)',
                                    paddingX: 3,
                                    color: 'white !important',
                                    fontWeight: 600,
                                    fontSize: 12,
                                    cursor: isSaving
                                        ? 'not-allowed'
                                        : 'pointer',
                                }}
                            >
                                {buttonClicked
                                    ? 'Saving Session'
                                    : 'Save Session'}
                            </Button>
                        </div>
                    </Tooltip>
                </Stack>
            </Box>
            <SessionOutcome />
            <CallSessionRadioGroup />
            {callSessionV2.action?.name === CallStatusEnum.CONTACT_SUCCESSFUL &&
                callSessionV2.sessionOutcome?.name ===
                    CallStatusOptionEnum.OPPORTUNITY && <OpportunityForm />}
        </Box>
    )
}

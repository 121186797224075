/**
 * @file DatagridComponents.tsx
 * @description This file contains the logic to render the data grid components for the opportunities page.
 */
import {
    Box,
    Button,
    Pagination,
    PaginationItem,
    Rating,
    RatingProps,
    Stack,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import {} from '@mui/x-data-grid'
import React, { useCallback } from 'react'
import { DateCalendar } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import formatters from 'utils/formatters'
import {
    GridColumnGroupingModel,
    GridFilterInputValueProps,
    GridFilterItem,
    GridFilterOperator,
    GridFooterContainer,
    GridPagination,
    useGridApiContext,
    useGridSelector,
    gridPageSelector,
    gridPageCountSelector,
} from '@mui/x-data-grid-pro'
import {
    FirstButton,
    LastButton,
    NextButton,
    PreviousButton,
} from '../../pages/utils'
import { FiLayers, FiUsers, FiRefreshCcw, FiPower } from 'react-icons/fi'
import BusinessIcon from '@mui/icons-material/Business'
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import HandshakeIcon from '@mui/icons-material/Handshake'

type LeadStatusType = {
    [key: string]: {
        bgColor: string
        color: string
    }
}
export const leadStatus: LeadStatusType = {
    new: {
        bgColor: 'rgba(62, 228, 134, 0.20)',
        color: '#227E4A',
    },
    open: {
        bgColor: 'rgba(129, 196, 210, 0.20)',
        color: '#01768F',
    },
    closed: {
        bgColor: 'rgba(206, 234, 104, 0.20)',
        color: '#708A3B',
    },
    'in progress': {
        bgColor: '#FEFFC8',
        color: '#CFA50E',
    },
    rejected: {
        bgColor: 'rgba(215, 48, 48, 0.2)',
        color: '#D73030',
    },
    active: {
        bgColor: 'rgba(25, 122, 86, 0.2)',
        color: 'rgba(25, 122, 86, 1)',
    },
}
export type TCampaignType = {
    [key: string]: {
        bgColor: string
        color: string
        borderColor: string
        icon: JSX.Element
    }
}

export const campaigns: TCampaignType = {
    reactivation: {
        bgColor: 'rgba(60, 165, 189, 0.2)',
        color: 'rgba(60, 165, 189, 1)',
        borderColor: 'rgba(60, 165, 189, 1)',
        icon: (
            <PowerSettingsNewIcon
                sx={{
                    fontSize: '15px',
                }}
            />
        ),
    },
    expansion: {
        bgColor: 'rgba(66, 70, 106, 0.2)',
        color: 'rgba(66, 70, 106, 1)',
        borderColor: 'rgba(66, 70, 106, 1)',
        icon: <FiLayers fontSize="15px" />,
    },
    retention: {
        bgColor: 'rgba(3, 69, 84, 0.2)',
        color: 'rgba(3, 69, 84, 1)',
        borderColor: 'rgba(3, 69, 84, 1)',
        icon: (
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <FiRefreshCcw fontSize="15px" />
                <FiUsers
                    fontSize="10px"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            </Box>
        ),
    },
    'biz rule': {
        bgColor: 'rgba(66, 70, 106, 0.2)',
        color: 'rgba(66, 70, 106, 1)',
        borderColor: 'rgba(66, 70, 106, 1)',
        icon: (
            <BusinessIcon
                sx={{
                    fontSize: '15px',
                }}
            />
        ),
    },
    inbound: {
        bgColor: 'rgba(2, 188, 21, 0.1)',
        color: 'rgba(0, 162, 36, 1)',
        borderColor: 'rgba(0, 162, 36, 1)',
        icon: (
            <PhoneCallbackIcon
                sx={{
                    fontSize: '15px',
                }}
            />
        ),
    },
    acquisition: {
        bgColor: 'rgba(205, 103, 177, 0.2)',
        color: 'rgba(170, 44, 157, 1)',
        borderColor: 'rgba(170, 44, 157, 1)',
        icon: (
            <HandshakeIcon
                sx={{
                    fontSize: '15px',
                }}
            />
        ),
    },
}

export const CustomPagination = () => {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)
    const useStyles = makeStyles(() => ({
        ul: {
            '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                    color: 'white',
                    borderRadius: '3px',
                },
            },
        },
    }))
    const classes = useStyles()
    const handlePageChange = useCallback(
        (event, value) => {
            apiRef.current.setPage(value - 1)
        },
        [apiRef]
    )
    return (
        <Stack spacing={2}>
            <Pagination
                sx={{
                    '& .MuiPaginationItem-root': {
                        color: 'rgba(119, 77, 252, 1)',
                    },
                    '& .Mui-selected': {
                        backgroundColor: 'rgba(119, 77, 252, 1)',
                        color: 'rgba(119, 77, 252, 1)',
                    },
                    '& .MuiPaginationItem-root:hover': {
                        backgroundColor: 'rgba(119, 77, 252, 0.2)',
                    },
                }}
                showFirstButton
                showLastButton
                count={pageCount}
                page={page + 1}
                onChange={handlePageChange}
                variant="text"
                shape="rounded"
                classes={{ root: classes.ul }}
                renderItem={(item) => (
                    <PaginationItem
                        slots={{
                            next: NextButton,
                            previous: PreviousButton,
                            first: FirstButton,
                            last: LastButton,
                        }}
                        {...item}
                    />
                )}
            />
        </Stack>
    )
}
export const CustomHeader = (props: any) => {
    const { activeButton, onSalesOrderClick, onQuotesClick } = props

    return (
        <Stack
            direction="row"
            spacing={0.5}
            bgcolor="rgba(188, 188, 188, 0.25)"
            sx={{
                padding: 1,
            }}
        >
            <Button
                size="small"
                onClick={onSalesOrderClick}
                sx={{
                    border:
                        activeButton !== 'Sales Order'
                            ? '1px solid #FFFFFF'
                            : '1px solid #774DFC',
                    background:
                        activeButton !== 'Sales Order' ? '#FFFFFF' : '#774DFC',
                    color:
                        activeButton === 'Sales Order' ? '#FFFFFF' : '#774DFC',
                    '&:hover': {
                        border: '1px solid #774DFC',
                        background:
                            activeButton !== 'Sales Order'
                                ? '#FFFFFF'
                                : '#774DFC',
                    },
                }}
            >
                Sales Order
            </Button>
            <Button
                size="small"
                onClick={onQuotesClick}
                sx={{
                    border:
                        activeButton !== 'Quote'
                            ? '1px solid #FFFFFF'
                            : '1px solid #774DFC',
                    background:
                        activeButton !== 'Quote' ? '#FFFFFF' : '#774DFC',
                    color: activeButton === 'Quote' ? '#FFFFFF' : '#774DFC',
                    '&:hover': {
                        border: '1px solid #774DFC',

                        background:
                            activeButton !== 'Quote' ? '#FFFFFF' : '#774DFC',
                    },
                }}
            >
                Quotes
            </Button>
        </Stack>
    )
}
export const CustomFooter = (props: any) => {
    return (
        <GridFooterContainer
            sx={{
                mr: 8,
            }}
        >
            <CustomPagination />
            <GridPagination
                {...props}
                nextIconButtonProps={{ hidden: true }}
                backIconButtonProps={{ hidden: true }}
                sx={{
                    '& .MuiTablePagination-displayedRows': {
                        display: 'none',
                    },
                    '& .MuiTablePagination-toolbar > .MuiInputBase-root': {
                        marginRight: '0',
                    },
                }}
            />
        </GridFooterContainer>
    )
}

function RatingInputValue(props: GridFilterInputValueProps) {
    const { item, applyValue, focusElementRef } = props

    const ratingRef: React.Ref<any> = React.useRef(null)
    React.useImperativeHandle(focusElementRef, () => ({
        focus: () => {
            ratingRef.current
                .querySelector(`input[value="${Number(item.value) || ''}"]`)
                .focus()
        },
    }))

    const handleFilterChange: RatingProps['onChange'] = useCallback(
        (event, newValue) => {
            applyValue({ ...item, value: newValue })
        },
        [item, applyValue]
    )
    return (
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: 48,
                pl: '20px',
            }}
        >
            <Rating
                name="custom-rating-filter-operator"
                placeholder="Filter value"
                value={Number(item.value)}
                onChange={handleFilterChange}
                precision={1}
                ref={ratingRef}
                sx={{
                    color: '#0F1835',
                    '& .MuiRating-icon': {
                        outline: 'none',
                    },
                    '& .MuiRating-icon:focus': {
                        outline: 'none',
                    },
                    '&:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiIconButton-root': {
                        // For MUI v4 or v5, target the root of the IconButton
                        outline: 'none !important',
                    },
                    '& .MuiIconButton-root.Mui-focusVisible': {
                        // For focus-visible state
                        outline: 'none !important',
                    },
                }}
            />
        </Box>
    )
}

export const ratingOnlyOperators: GridFilterOperator[] = [
    {
        label: 'equals',
        value: 'equals',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (
                !filterItem.columnField ||
                !filterItem.value ||
                !filterItem.operatorValue
            ) {
                return null
            }

            return (params): boolean => {
                return Number(params.value) === Number(filterItem.value)
            }
        },
    },
]

function DateInput(props: any) {
    const { item, applyValue, actionStatus } = props
    const handleFilterChange = useCallback(
        (newValue: DateTime) => {
            const formattedDate = newValue
                ? actionStatus == 'today'
                    ? formatters.formatDate(
                          newValue.toISO(),
                          'America/Chicago',
                          'utc'
                      ).localDate
                    : formatters.formatDate(
                          newValue.toISO(),
                          'America/Chicago',
                          'utc'
                      ).isoDate
                : ''
            applyValue({ ...item, value: formattedDate })
        },
        [item, applyValue, actionStatus]
    )
    return (
        <Box>
            <DateCalendar onChange={handleFilterChange} />
        </Box>
    )
}

export const dateOperator: GridFilterOperator[] = [
    {
        label: 'equals',
        value: 'equals',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (
                !filterItem.columnField ||
                !filterItem.value ||
                !filterItem.operatorValue
            ) {
                return null
            }

            return (params) => {
                return params.value === filterItem.value
            }
        },
        InputComponent: DateInput,
        InputComponentProps: { type: 'DateTime' },
    },
]

export const columnGroupingModel: GridColumnGroupingModel = [
    {
        groupId: 'CUSTOMER INFORMATION',
        headerName: 'CUSTOMER INFORMATION',
        headerClassName: 'column-group-customer-info',
        renderHeaderGroup: () => {
            return (
                <Typography fontFamily={'Inter'} fontSize={10} fontWeight={700}>
                    CUSTOMER INFORMATION
                </Typography>
            )
        },
        children: [
            { field: 'account_id' },
            { field: 'account_name' },
            { field: 'account_industry' },
            { field: 'no_of_contacts' },
        ],
    },
    {
        groupId: 'LEAD INFORMATION',
        headerName: 'LEAD INFORMATION',
        headerClassName: 'column-group-lead-info',
        renderHeaderGroup: () => {
            return (
                <Typography fontFamily={'Inter'} fontSize={10} fontWeight={700}>
                    LEAD INFORMATION
                </Typography>
            )
        },
        children: [
            { field: 'campaign_type' },
            { field: 'account_ownership' },
            { field: 'initiatives' },
            { field: 'item_name' },
            { field: 'propensity' },
            { field: 'latest_interaction_date' },
            { field: 'creation_date' },
            { field: 'status' },
            { field: 'follow_up_date' },
            { field: 'days_left_duration' },
        ],
    },
]

export const formatDateDifference = (days: number | null) => {
    if (days === null) return '--'
    if (days > 1) return `${days} Days`
    if (days === 1) return `${days} Day`
    if (days === 0) return 'Today'
    if (days === -1) return `${-days} Day Ago`
    if (days < -1) return `${-days} Days Ago`
}

/**
 * @file OpportunitiesProvider.tsx
 * @description This file contains the implementation of the OpportunitiesProvider component and the useOpportunitiesSession hook.
 * @description It provides a context for managing and displaying opportunities on the List page.
 */

import { BuddyAPIClient } from 'api'
import {
    IAccountCounts,
    IColumn,
    ILeadList,
    IGroup,
    IAPIParams,
} from 'buddy-api'
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useSnackBarAlert } from './SnackBarAlertProvider'
import {
    GridFilterItem,
    GridFilterModel,
    GridLinkOperator,
    GridSortModel,
} from '@mui/x-data-grid-pro'
import useSortableColumns, { TSortState } from 'hooks/useSortState'
import { callSessionPageURL } from 'routes/routes'
import { ListResponseWrapper } from 'buddy-api/ApiMixin'
import { v4 as uuidv4 } from 'uuid'
import { debounce } from 'lodash'

type IOpportunitiesContext = {
    counts?: IAccountCounts
    loading: boolean
    status: TStatus
    page: number
    pageSize: number
    sortOrder: TSortState
    opportunities: ILeadList[]
    rowCount: number
    sortModel: GridSortModel
    queryOptions: any
    searchResults: ListResponseWrapper<ILeadList> | undefined
    filterOptions: TFilterOptions
    filterState: TFilterValues
    filterModel: GridFilterModel
    setStatusCallback: (value: TStatus) => void
    fetchSearchResultsCallback: (searchString: string) => void
    handlePageSizeCallback: (newPageSize: number) => void
    getRowIdCallback: (row: ILeadList) => string
    handleRowClickCallback: (params: any) => void
    setToggleSortCallback: (field: string) => void
    onFilterModelChangeCallback: (filterModel: GridFilterModel) => void
    onSortModelChangeCallback: (sortItems: GridSortModel) => void
    handlePageChangeCallback: (newPage: number) => void
    handleButtonClickCallback: (value: ILeadList, count: number) => void
    handleFilterStateChangeCallback: (
        columnField: keyof TFilterValues,
        filters: string
    ) => void
}

export const OpportunitiesContext = createContext<IOpportunitiesContext>(
    {} as IOpportunitiesContext
)

export const useOpportunities = () => useContext(OpportunitiesContext)

export type TStatus =
    | 'new'
    | 'today'
    | 'open'
    | 'in_progress'
    | 'closed'
    | 'opportunity'
    | 'all'

type ColumnGroup = IGroup & {
    columns: IColumn[]
}
type ColumnOrGroup = IColumn | ColumnGroup

export const isColumn = (c: ColumnOrGroup): c is IColumn => !('columns' in c)

const toOrderBy = (sort: GridSortModel) => {
    if (sort.length === 0) return ''
    const item = sort[0]
    return item.sort === 'desc' ? `-${item.field}` : item.field
}

export type TFilterOptions = {
    account_industry: string[]
    initiatives: string[]
    campaign_type: string[]
    account_ownership: string[]
    item_name: string[]
    propensity: string[]
    creation_date: string[]
    status: string[]
    follow_up_date: string[]
    days_left_duration: string[]
    no_of_contacts: string[]
}

export type TFilterValues = {
    account_industry: string
    initiatives: string
    campaign_type: string
    account_ownership: string
    item_name: string
    propensity: string
    creation_date: string
    status: string
    follow_up_date: string
    days_left_duration: string
    no_of_contacts: string
}

const OpportunitiesProvider: React.FC = ({ children }) => {
    const { showAlert } = useSnackBarAlert()
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState<TStatus>('today')
    const [counts, setCounts] = useState<IAccountCounts>({
        all: 0,
        open: 0,
        closed: 0,
        in_progress: 0,
        opportunity: 0,
        today: 0,
        new: 0,
    })
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [opportunities, setOpportunities] = useState<ILeadList[]>([])
    const [rowCount, setRowCount] = useState(0)
    const [searchResults, setSearchResults] = useState<
        ListResponseWrapper<ILeadList>
    >({
        count: 0,
        next: null,
        previous: null,
        results: [],
    })
    const [todayDataLoaded, setTodayDataLoaded] = useState<boolean>(false)
    const [sortOrder, toggleSort] = useSortableColumns({
        account_id: null,
        account_name: null,
        account_industry: null,
        campaign_type: null,
        account_ownership: null,
        initiatives: null,
        item_name: null,
        propensity: null,
        latest_interaction_date: null,
        creation_date: null,
        status: null,
        follow_up_date: null,
        days_left_duration: null,
        no_of_contacts: null,
    })
    const [filterState, setFilterState] = useState<TFilterValues>({
        account_industry: '',
        campaign_type: '',
        account_ownership: '',
        initiatives: '',
        item_name: '',
        propensity: '',
        creation_date: '',
        status: '',
        follow_up_date: '',
        days_left_duration: '',
        no_of_contacts: '',
    })
    const [filterOptions, setFilterOptions] = useState<TFilterOptions>({
        account_industry: [],
        initiatives: [],
        campaign_type: [
            'REACTIVATION',
            'EXPANSION',
            'RETENTION',
            'ACQUISITION',
            'BIZ RULE',
        ],
        account_ownership: ['Managed', 'Unmanaged'],
        item_name: [],
        propensity: ['1', '2', '3', '4', '5'],
        creation_date: [],
        status: [
            'New',
            'Open',
            'In Progress',
            'Closed: Opportunity',
            'Rejected',
            'All',
        ],
        follow_up_date: [],
        days_left_duration: [],
        no_of_contacts: [],
    })
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [queryOptions, setQueryOptions] = useState<any>()
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [],
        linkOperator: GridLinkOperator.And,
    })

    const handleFilterStateChangeCallback = useCallback(
        async (columnField: keyof TFilterOptions, filters: string) => {
            setFilterState((prevState) => ({
                ...prevState,
                [columnField]: filters,
            }))
        },
        []
    )

    const fetchItemNamesCallback = useCallback(async () => {
        const { results } = await BuddyAPIClient.TalkTrack.list({
            limit: 320,
            offset: 0,
        })
        const filteredNames = results.map((obj) => obj.name)
        setFilterOptions((prev) => ({
            ...prev,
            item_name: filteredNames,
        }))
    }, [])

    const setToggleSortCallback = useCallback(
        async (field: string) => {
            toggleSort(field)
        },
        [toggleSort]
    )

    const handleButtonClickCallback = useCallback(
        async (result: ILeadList, count: number) => {
            setPage(0)
            setRowCount(1)
            setOpportunities([result])
        },
        [setPage, setOpportunities, setRowCount]
    )

    const handleRowClickCallback = useCallback(async (params: any) => {
        const encoded_id = encodeURIComponent(params.row.account_id)
        const url = callSessionPageURL(encoded_id)
        window.open(url, '_blank')
    }, [])

    const getRowIdCallback = useCallback((row: ILeadList) => {
        return uuidv4()
    }, [])

    const handlePageSizeCallback = useCallback(
        async (newPageSize: number) => {
            setPageSize(newPageSize)
        },
        [setPageSize]
    )

    const handlePageChangeCallback = useCallback(
        async (newPage: number) => {
            setPage(newPage)
        },
        [setPage]
    )

    const onSortModelChangeCallback = useCallback(
        async (sortItems) => {
            setSortModel(sortItems)
        },
        [setSortModel, sortModel]
    )

    const onFilterModelChangeCallback = useCallback(
        async (filterModel: GridFilterModel) => {
            const filteredItems = filterModel.items.map((item) => {
                if (item.value === undefined) {
                    return {
                        ...item,
                        value: '',
                    }
                }
                return item
            })
            setQueryOptions({
                filterModel,
                items: filteredItems,
            })
        },
        [filterModel]
    )

    const setStatusCallback = useCallback(
        async (value: TStatus) => {
            setPage(0)
            setStatus(value)
        },
        [setStatus]
    )

    const fetchSearchResultsCallback = useCallback(
        async (searchString: string) => {
            try {
                setLoading(true)
                const queryParams: IAPIParams = {
                    search: searchString,
                }
                const _searchResults = await BuddyAPIClient.AccountDetails.list(
                    queryParams
                )
                setSearchResults(_searchResults)
            } catch (e) {
                showAlert('error', 'Error Fetching search results', 6000)
                console.error(e)
            } finally {
                setLoading(false)
            }
        },
        []
    )
    const fetchCountsCallback = useCallback(async () => {
        try {
            const res = await BuddyAPIClient.AccountDetails.counts()
            setCounts((prev) => ({ ...prev, ...res }))
        } catch (e) {
            showAlert('error', 'Error fetching counts', 6000)
        }
    }, [setCounts, showAlert])

    const fetchRecordsCallback = useCallback(
        debounce(async () => {
            setLoading(true)
            setOpportunities([])
            try {
                let queryParams

                if (status === 'today') {
                    queryParams = { status }
                } else {
                    queryParams = {
                        ordering: toOrderBy(sortModel),
                        limit: pageSize,
                        offset: pageSize * page,
                        queryOptions: queryOptions,
                        status,
                    }
                }
                const _opportunities = await BuddyAPIClient.AccountDetails.list(
                    queryParams
                )
                setOpportunities(_opportunities.results)
                setCounts((prev) => ({
                    ...prev,
                    [status]: _opportunities.count,
                }))
                setRowCount(_opportunities.count ?? 0)
                if (status === 'today') {
                    setTodayDataLoaded(true)
                } else {
                    setTodayDataLoaded(false)
                }
            } catch (e) {
                showAlert(
                    'error',
                    'Error fetching customer recommendations!',
                    6000
                )
                console.error(e)
            } finally {
                setLoading(false)
            }
        }, 500),
        [page, pageSize, sortModel, status, queryOptions]
    )

    useEffect(() => {
        const fetchToday =
            status === 'today' &&
            !todayDataLoaded &&
            sortModel.length === 0 &&
            filterModel.items.length === 0

        const fetchOthers =
            status !== 'today' &&
            (sortModel.length >= 0 || filterModel.items.length >= 0)

        if (fetchToday || fetchOthers) {
            fetchRecordsCallback()?.catch((e) => {
                showAlert('error', 'Error fetching Opportunities', 6000)
                console.error(e)
            })
        }
        return () => {
            fetchRecordsCallback.cancel()
        }
    }, [status, page, sortModel, queryOptions, fetchRecordsCallback])

    useEffect(() => {
        const filters: GridFilterModel['items'] = []
        Object.keys(filterState).forEach((columnField) => {
            const filterValue =
                filterState[columnField as keyof typeof filterState]
            const uniqueId = uuidv4()
            if (filterValue.length > 0) {
                filters.push({
                    id: uniqueId,
                    columnField,
                    operatorValue:
                        columnField === 'account_industry'
                            ? 'contains'
                            : 'equals',
                    value: filterValue,
                })
            }
        })
        setFilterModel({
            items: filters,
            linkOperator: GridLinkOperator.And,
        })
    }, [filterState])

    useEffect(() => {
        const filteredItems = filterModel.items.map((item) => {
            if (item.value === undefined) {
                return {
                    ...item,
                    value: '',
                }
            }
            return item
        })
        setQueryOptions({
            filterModel,
            items: filteredItems,
        })
    }, [filterModel])

    useEffect(() => {
        const newSortModel = Object.keys(sortOrder)
            .filter((key) => sortOrder[key] !== null)
            .map((key) => ({
                field: key,
                sort: sortOrder[key],
            }))
        setSortModel(newSortModel)
    }, [sortOrder, setSortModel])

    useEffect(() => {
        fetchCountsCallback()
        fetchItemNamesCallback()
    }, [fetchCountsCallback, fetchItemNamesCallback])

    useEffect(() => {
        const handleFocus = () => {
            fetchCountsCallback()
            fetchRecordsCallback()
        }
        window.addEventListener('focus', handleFocus)
        return () => {
            window.removeEventListener('focus', handleFocus)
        }
    }, [fetchRecordsCallback, fetchCountsCallback])

    const context = useMemo(() => {
        return {
            counts,
            loading,
            status,
            page,
            pageSize,
            sortOrder,
            opportunities,
            rowCount,
            sortModel,
            queryOptions,
            searchResults,
            filterOptions,
            filterState,
            filterModel,
            getRowIdCallback,
            handleRowClickCallback,
            setStatusCallback,
            fetchSearchResultsCallback,
            handlePageSizeCallback,
            setToggleSortCallback,
            onFilterModelChangeCallback,
            onSortModelChangeCallback,
            handlePageChangeCallback,
            handleButtonClickCallback,
            handleFilterStateChangeCallback,
        }
    }, [
        counts,
        loading,
        status,
        page,
        pageSize,
        sortOrder,
        opportunities,
        rowCount,
        sortModel,
        queryOptions,
        searchResults,
        filterOptions,
        filterState,
        filterModel,
        getRowIdCallback,
        handleRowClickCallback,
        setStatusCallback,
        fetchSearchResultsCallback,
        handlePageSizeCallback,
        setToggleSortCallback,
        onFilterModelChangeCallback,
        onSortModelChangeCallback,
        handlePageChangeCallback,
        handleButtonClickCallback,
        handleFilterStateChangeCallback,
    ])

    return (
        <OpportunitiesContext.Provider value={context}>
            {children}
        </OpportunitiesContext.Provider>
    )
}

function useOpportunitiesSession() {
    const context = React.useContext(OpportunitiesContext)
    if (context === undefined) {
        throw new Error(
            'useOpportunitiesSession must be used within a OpportunitiesProvider'
        )
    }
    return context
}

export { OpportunitiesProvider, useOpportunitiesSession }

/**
 * @file TableColumns.tsx
 * @description This file contains the logic to create the columns for the data grid.
 */
import { Typography, Stack, Box, Tooltip, Button } from '@mui/material'
import { TStatus } from 'context/OpportunitiesProvider'
import { ILeadList } from 'buddy-api'
import { GridColDef, getGridStringOperators } from '@mui/x-data-grid-pro'
import { CustomTableHeader } from './CustomTableHeader'
import {
    campaigns,
    dateOperator,
    formatDateDifference,
    leadStatus,
    ratingOnlyOperators,
} from './DatagridComponents'
import formatters from 'utils/formatters'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import StarIcon from '@mui/icons-material/Star'
import { TSortState } from 'hooks/useSortState'
import CustomText from 'components/CustomText'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { DateTime } from 'luxon'
export const TableColumns = (
    status: TStatus,
    sortOrder: TSortState,
    setToggleSortCallback: (field: string) => void
) => {
    const tableColumns: GridColDef<ILeadList>[] = [
        {
            field: 'account_id',
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            renderHeader: () => (
                <CustomTableHeader
                    headerName={'ACCOUNT ID'}
                    isFilterEnabled={false}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'account_id'}
                    filterOperators={['contains', 'equals']}
                ></CustomTableHeader>
            ),
            valueGetter: (params) => params.row.account_id ?? 0,
            sortComparator: (v1, v2) => {
                return v1 - v2
            },
            minWidth: 80,
            flex: 1,
            renderCell: (params) => {
                const date =
                    params.row.last_interaction_date &&
                    params.row.last_interaction_date.split('T')[0]
                return (
                    <>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            {date ===
                                new Date().toISOString().split('T')[0] && (
                                <CheckCircleIcon
                                    fontSize="small"
                                    sx={{
                                        color: 'rgba(0, 162, 36, 1)',
                                        ml: 2,
                                    }}
                                />
                            )}
                            <a
                                href={`/agent/callsession/${params?.row?.account_id}`}
                                target="_blank"
                            >
                                <Typography
                                    fontFamily={'Inter'}
                                    fontSize={12}
                                    fontWeight={500}
                                    color={'#000021'}
                                    textAlign={'right'}
                                    ml={1}
                                >
                                    {params?.row?.account_id ?? 0}
                                </Typography>
                            </a>
                        </Box>
                    </>
                )
            },
        },
        {
            field: 'account_name',
            headerClassName: 'custom-header-style',
            sortable: false,
            filterable: false,
            renderHeader: () => (
                <CustomTableHeader
                    headerName={'ACCOUNT'}
                    isFilterEnabled={false}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'account_name'}
                    filterOperators={['contains', 'equals']}
                ></CustomTableHeader>
            ),
            minWidth: 150,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={1} alignItems="center">
                        {params.row.email_flag && (
                            <CustomText
                                backgroundColor="rgba(119, 77, 252, 1)"
                                textColor="rgba(255, 255, 255, 1)"
                                displayText="EMAIL"
                            ></CustomText>
                        )}
                        {params.row.inbound && (
                            <CustomText
                                backgroundColor="rgba(46, 158, 238, 1)"
                                textColor="rgba(255, 255, 255, 1)"
                                displayText="WEB"
                            ></CustomText>
                        )}
                        <Typography
                            fontFamily={'Inter'}
                            fontSize={12}
                            fontWeight={400}
                            color={'#3A404D'}
                        >
                            {params?.row?.account_name ?? '--'}
                        </Typography>
                    </Stack>
                )
            },
        },
        {
            field: 'no_of_contacts',
            headerClassName: 'custom-header-style',
            filterable: false,
            sortable: false,
            renderHeader: () => (
                <CustomTableHeader
                    headerName={
                        <>
                            NO OF <br /> CONTACTS
                        </>
                    }
                    isFilterEnabled={false}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'no_of_contacts'}
                    filterOperators={['contains', 'equals']}
                ></CustomTableHeader>
            ),
            valueGetter: (params) => params.row.no_of_contacts ?? 0,
            sortComparator: (v1, v2) => {
                return v1 - v2
            },
            minWidth: 80,
            flex: 1,
            renderCell: (params) => {
                const date =
                    params.row.last_interaction_date &&
                    params.row.last_interaction_date.split('T')[0]
                return (
                    <>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            sx={{
                                bgcolor: params?.row?.new_contact_flag
                                    ? '#CBD8FF'
                                    : '',
                                paddingX: '12px',
                                paddingY: '4px',
                                borderRadius: '4px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '75%',
                            }}
                        >
                            <a
                                href={`/agent/callsession/${params?.row?.no_of_contacts}`}
                                target="_blank"
                            >
                                {params?.row?.new_contact_flag ? (
                                    <Tooltip
                                        title={
                                            <Box
                                                sx={{
                                                    bgcolor: params?.row
                                                        ?.new_contact_flag
                                                        ? '#CBD8FF'
                                                        : '',

                                                    borderRadius: '4px',
                                                    alignItems: 'center',
                                                    paddingX: '12px',
                                                    paddingY: '4px',
                                                }}
                                            >
                                                {params?.row
                                                    ?.new_contact_flag && (
                                                    <>
                                                        <h3>
                                                            {params.row
                                                                .account_id ??
                                                                0}
                                                        </h3>
                                                        <br />
                                                        <p
                                                            style={{
                                                                color: 'black',
                                                                fontSize:
                                                                    '13px',
                                                            }}
                                                        >
                                                            New Contact(s) added
                                                        </p>
                                                    </>
                                                )}
                                            </Box>
                                        }
                                    >
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            height="0px"
                                        >
                                            <Typography
                                                fontFamily={'Inter'}
                                                fontSize={12}
                                                fontWeight={500}
                                                color={'#000021'}
                                                textAlign={'right'}
                                                ml={1}
                                            >
                                                {params?.row?.no_of_contacts ??
                                                    0}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    marginLeft: '5px',
                                                    color: '#66CF6A',
                                                    marginBottom: '35px',
                                                    fontSize: 'xxx-large',
                                                }}
                                            >
                                                .
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                ) : (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        height="0px"
                                    >
                                        <Typography
                                            fontFamily={'Inter'}
                                            fontSize={12}
                                            fontWeight={500}
                                            color={'#000021'}
                                            textAlign={'right'}
                                            ml={1}
                                        >
                                            {params?.row?.no_of_contacts ?? 0}
                                        </Typography>
                                    </Box>
                                )}
                            </a>
                        </Box>
                    </>
                )
            },
        },
        {
            sortable: false,
            headerClassName: 'custom-header-style',
            field: 'account_industry',
            headerName: 'INDUSTRY',
            renderHeader: () => (
                <CustomTableHeader
                    headerName={'INDUSTRY'}
                    isFilterEnabled={true}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'account_industry'}
                    filterOperators={['contains', 'equals']}
                ></CustomTableHeader>
            ),
            minWidth: 160,
            flex: 1,
            filterOperators: getGridStringOperators().filter(
                (operator) =>
                    operator.value === 'equals' || operator.value === 'contains'
            ),
            renderCell: (params) => {
                return (
                    <Typography
                        fontFamily={'Inter'}
                        fontSize={12}
                        fontWeight={400}
                        color={'#000021'}
                    >
                        {params?.row?.account_industry ?? '--'}
                    </Typography>
                )
            },
        },
        {
            sortable: false,
            headerClassName: 'custom-header-style',
            field: 'campaign_type',
            headerName: 'CAMPAIGN',
            renderHeader: () => (
                <CustomTableHeader
                    headerName={'CAMPAIGN'}
                    isFilterEnabled={true}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'campaign_type'}
                    filterOperators={['contains', 'equals']}
                ></CustomTableHeader>
            ),
            minWidth: 160,
            width: 160,
            flex: 1,
            filterOperators: getGridStringOperators().filter(
                (operator) =>
                    operator.value === 'equals' || operator.value === 'contains'
            ),
            renderCell: (params) => {
                const campaignType = params?.row?.campaign_type?.toLowerCase()
                const CampaignIcon = campaigns[campaignType]?.icon
                return (
                    <Box
                        sx={{
                            bgcolor:
                                campaigns[
                                    `${params?.row?.campaign_type?.toLowerCase()}`
                                ]?.bgColor,
                            paddingX: '12px',
                            paddingY: '4px',
                            borderTop: `0.2px solid ${
                                campaigns[
                                    `${params?.row?.campaign_type?.toLowerCase()}`
                                ]?.borderColor
                            }`,
                            borderBottom: `0.2px solid ${
                                campaigns[
                                    `${params?.row?.campaign_type?.toLowerCase()}`
                                ]?.borderColor
                            }`,
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            justifyContent: 'center',
                            width: '140px',
                            maxWidth: '100%',
                            color: campaigns[
                                `${params?.row?.campaign_type?.toLowerCase()}`
                            ]?.color,
                        }}
                    >
                        {CampaignIcon}
                        <Typography
                            fontWeight={700}
                            fontFamily={'Inter'}
                            fontSize={11}
                            variant="button"
                            sx={{ textTransform: 'uppercase' }}
                        >
                            {params?.row?.campaign_type ?? '--'}
                        </Typography>
                    </Box>
                )
            },
        },
        {
            sortable: false,
            headerClassName: 'custom-header-style',
            field: 'account_ownership',
            headerName: 'ACCOUNT OWNERSHIP',
            renderHeader: () => (
                <CustomTableHeader
                    headerName={
                        <>
                            ACCOUNT <br></br> OWNERSHIP
                        </>
                    }
                    isFilterEnabled={true}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'account_ownership'}
                    filterOperators={['contains', 'equals']}
                ></CustomTableHeader>
            ),
            minWidth: 100,
            flex: 1,
            filterOperators: getGridStringOperators().filter(
                (operator) =>
                    operator.value === 'equals' || operator.value === 'contains'
            ),
            renderCell: (params) => {
                return (
                    <Typography
                        fontFamily={'Inter'}
                        fontSize={12}
                        fontWeight={500}
                        color={'#000021'}
                        textAlign={'right'}
                        ml={1}
                    >
                        {params?.row?.managed_flag ? 'Managed' : 'Unmanaged'}
                    </Typography>
                )
            },
        },
        {
            sortable: false,
            headerClassName: 'custom-header-style',
            field: 'initiatives',
            headerName: 'INITIATIVES',
            renderHeader: () => (
                <CustomTableHeader
                    headerName={'INITIATIVES'}
                    isFilterEnabled={true}
                    isSortEnabled={false}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'initiatives'}
                    filterOperators={['contains', 'equals']}
                ></CustomTableHeader>
            ),
            minWidth: 100,
            flex: 1,
            filterOperators: getGridStringOperators().filter(
                (operator) =>
                    operator.value === 'equals' || operator.value === 'contains'
            ),
            renderCell: (params) => {
                const arr = params.row.campaign_target_list
                return (
                    <Typography
                        fontFamily={'Inter'}
                        fontSize={12}
                        fontWeight={400}
                        color={'#000021'}
                    >
                        {arr ? arr.join(', ') : '--'}
                    </Typography>
                )
            },
        },
        {
            headerClassName: 'custom-header-style',
            field: 'item_name',
            headerName: 'REPORTING CATEGORY',
            renderHeader: () => (
                <CustomTableHeader
                    headerName={
                        <>
                            REPORTING <br></br> CATEGORY
                        </>
                    }
                    isFilterEnabled={true}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'item_name'}
                    filterOperators={['contains', 'equals']}
                ></CustomTableHeader>
            ),
            sortable: false,
            minWidth: 100,
            flex: 1,
            filterOperators: getGridStringOperators().filter(
                (operator) =>
                    operator.value === 'equals' || operator.value === 'contains'
            ),
            renderCell: (params) => {
                return (
                    <Typography
                        fontFamily={'Inter'}
                        fontSize={12}
                        fontWeight={400}
                        color={'#000021'}
                    >
                        {params?.row?.item_name ?? '--'}
                    </Typography>
                )
            },
        },
        {
            field: 'propensity',
            headerClassName: 'custom-header-style',
            headerName: 'LEAD PROPENSITY',
            renderHeader: () => (
                <CustomTableHeader
                    headerName={
                        <>
                            LEAD <br></br> PROPENSITY
                        </>
                    }
                    isFilterEnabled={true}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'propensity'}
                    filterOperators={['contains', 'equals']}
                ></CustomTableHeader>
            ),
            sortable: false,
            minWidth: 100,
            flex: 1,
            valueGetter: (params) => params.row.propensity,
            filterOperators: ratingOnlyOperators,
            renderCell: (params) => {
                let starRating = parseFloat(params?.row?.propensity?.toString())
                const decimal = starRating - Math.floor(starRating)
                if (decimal < 0.5) {
                    starRating = Math.floor(starRating)
                } else {
                    starRating = Math.ceil(starRating)
                }
                const stars = []
                for (let i = 0; i < 5; i++) {
                    if (i <= starRating - 1) {
                        stars.push(
                            <StarIcon
                                key={`star-${params?.row?.lead_id}-${params.row.propensity}-${i}`}
                                fontSize="small"
                                sx={{
                                    color: '#0F1835',
                                    width: '16px',
                                    height: '16px',
                                }}
                            />
                        )
                    } else {
                        stars.push(
                            <StarIcon
                                key={`star-${params?.row?.lead_id}-${params.row.propensity}-${i}`}
                                fontSize="small"
                                sx={{
                                    color: 'rgba(188, 188, 188, 0.6)',
                                    width: '16px',
                                    height: '16px',
                                }}
                            />
                        )
                    }
                }
                return <Box>{stars.map((star) => star)}</Box>
            },
        },
        {
            field: 'latest_interaction_date',
            headerClassName: 'custom-header-style',
            headerName: 'LAST INTERACTION',
            renderHeader: () => (
                <CustomTableHeader
                    headerName={
                        <>
                            LAST <br /> INTERACTION
                        </>
                    }
                    isFilterEnabled={false}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'latest_interaction_date'}
                    filterOperators={['contains', 'equals']}
                />
            ),
            sortable: false,
            filterOperators: dateOperator.map((operator) => ({
                ...operator,
                InputComponentProps: {
                    ...operator.InputComponentProps,
                    actionStatus: status,
                },
            })),
            valueGetter: (params) =>
                params.row.last_interaction_date &&
                formatters.formatDate(
                    params.row.last_interaction_date,
                    'America/Chicago',
                    'utc'
                )?.localDate,

            sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime()
            },
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                const date = formatters.formatDate(
                    params.row.last_interaction_date ?? '',
                    'utc',
                    'America/Chicago'
                )
                const todayDate = DateTime.now().setZone('utc').startOf('day')
                const dateToCheck = DateTime.fromISO(
                    params.row.last_interaction_date ?? '',
                    {
                        zone: 'utc',
                    }
                )
                    .setZone('utc')
                    .startOf('day')
                const diff = todayDate.diff(dateToCheck, 'days')
                const daysLeft = Math.round(diff.days)
                return (
                    <Typography
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={
                            params?.row?.managed_flag
                                ? daysLeft && daysLeft > 60
                                    ? '600'
                                    : '400'
                                : '400'
                        }
                        color={
                            params?.row?.managed_flag
                                ? daysLeft && daysLeft > 60
                                    ? 'rgba(231, 62, 48, 1)'
                                    : '#000021'
                                : '#000021'
                        }
                        bgcolor={
                            params?.row?.managed_flag &&
                            daysLeft &&
                            daysLeft > 60
                                ? 'rgba(215, 48, 48, 0.2)'
                                : ''
                        }
                        display={'flex'}
                        alignItems={'center'}
                        width="100%"
                        height="100%"
                    >
                        {date?.localDate ?? '--'}
                    </Typography>
                )
            },
        },
        {
            field: 'creation_date',
            headerClassName: 'custom-header-style',
            headerName: 'SENT TO BUDDY DATE',
            renderHeader: () => (
                <CustomTableHeader
                    headerName={
                        <>
                            SENT TO <br></br> BUDDY DATE
                        </>
                    }
                    isFilterEnabled={true}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'creation_date'}
                    filterOperators={['contains', 'equals']}
                ></CustomTableHeader>
            ),
            sortable: false,
            filterOperators: dateOperator.map((operator) => ({
                ...operator,
                InputComponentProps: {
                    ...operator.InputComponentProps,
                    actionStatus: status,
                },
            })),
            valueGetter: (params) =>
                params.row.created_at &&
                formatters.formatDate(
                    params.row.created_at,
                    'America/Chicago',
                    'utc'
                )?.localDate,

            sortComparator: (v1, v2) => {
                return new Date(v1).getTime() - new Date(v2).getTime()
            },
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                const date = formatters.formatDate(
                    params?.row?.created_at ?? '',
                    'utc',
                    'America/Chicago'
                )
                return (
                    <Typography
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={400}
                        color="#000021"
                    >
                        {date?.localDate ?? '--'}
                    </Typography>
                )
            },
        },
        {
            field: 'status',
            headerClassName: 'custom-header-style',
            headerName: 'STATUS',
            renderHeader: () => (
                <CustomTableHeader
                    headerName={
                        <>
                            LEAD <br></br> STATUS
                        </>
                    }
                    isFilterEnabled={true}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'status'}
                    filterOperators={['contains', 'equals']}
                ></CustomTableHeader>
            ),
            sortable: false,
            filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'equals'
            ),
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            bgcolor:
                                leadStatus[
                                    `${params.row.status?.toLowerCase()}`
                                ]?.bgColor,
                            paddingX: '12px',
                            paddingY: '4px',
                            borderRadius: '4px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            fontWeight={700}
                            fontFamily={'Inter'}
                            fontSize={10}
                            color={
                                leadStatus[
                                    `${params.row.status?.toLowerCase()}`
                                ]?.color
                            }
                            variant="button"
                            sx={{ textTransform: 'uppercase' }}
                        >
                            {params?.row?.status ?? '--'}
                        </Typography>
                    </Box>
                )
            },
        },
        {
            field: 'follow_up_date',
            headerClassName: 'custom-header-style',
            headerName: 'Follow Up Date',
            renderHeader: () => (
                <CustomTableHeader
                    headerName={
                        <>
                            FOLLOW <br /> UP
                        </>
                    }
                    isFilterEnabled={true}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'follow_up_date'}
                    filterOperators={['equals']}
                ></CustomTableHeader>
            ),
            sortable: false,
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                const daysLeft = formatters.getDifferenceInDays(
                    params?.row?.follow_up_date ?? ''
                )
                return (
                    <Stack direction="row" alignItems="center" gap={1}>
                        <AccessAlarmIcon fontSize="small" />
                        <Typography
                            fontFamily="Inter"
                            fontSize={12}
                            fontWeight={400}
                            color="#000021"
                        >
                            {formatDateDifference(daysLeft)}
                        </Typography>
                    </Stack>
                )
            },
        },
        {
            field: 'days_left_duration',
            headerClassName: 'custom-header-style',
            headerName: 'DAYS LEFT',
            renderHeader: () => (
                <CustomTableHeader
                    headerName={
                        <>
                            DAYS <br></br> LEFT
                        </>
                    }
                    isFilterEnabled={true}
                    isSortEnabled={true}
                    sortOrder={sortOrder}
                    toggleSort={setToggleSortCallback}
                    columnField={'days_left_duration'}
                    filterOperators={['contains', 'equals']}
                ></CustomTableHeader>
            ),
            valueGetter: (params) => params.row.days_left,
            filterOperators: [
                {
                    label: 'equals',
                    value: 'equals',
                    getApplyFilterFn: (filterItem) => {
                        return (params) => {
                            if (!params) {
                                return false
                            }
                            return params.value == filterItem.value
                        }
                    },
                },
            ],
            sortable: false,
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        sx={{
                            bgcolor:
                                Number(params?.row?.days_left) < 3
                                    ? '#FAA0A0'
                                    : '',
                            paddingX: '12px',
                            paddingY: '4px',
                            borderRadius: '4px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '75%',
                        }}
                    >
                        {Number(params?.row?.days_left) < 3 ? (
                            <Tooltip
                                title={
                                    <Box
                                        sx={{
                                            bgcolor:
                                                Number(params?.row?.days_left) <
                                                3
                                                    ? '#CBD8FF'
                                                    : '',

                                            borderRadius: '4px',
                                            alignItems: 'center',
                                            paddingX: '12px',
                                            paddingY: '4px',
                                        }}
                                    >
                                        {Number(params?.row?.days_left) < 3 && (
                                            <>
                                                <h3>
                                                    {params.row.account_id ?? 0}
                                                </h3>
                                                <br />
                                                <p
                                                    style={{
                                                        color: 'black',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    Extend Expiry Date ?
                                                </p>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    <a
                                                        href={`/agent/callsession/${params?.row?.account_id}`}
                                                        target="_blank"
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    >
                                                        Click here
                                                    </a>
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                }
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    height="0px"
                                >
                                    <Typography
                                        fontFamily={'Inter'}
                                        fontSize={12}
                                        fontWeight={500}
                                        color={'#000021'}
                                        textAlign={'right'}
                                        ml={1}
                                    >
                                        {params?.row?.days_left ?? 0}
                                    </Typography>
                                    <Typography
                                        style={{
                                            marginLeft: '5px',
                                            color: '#EE4B2B',
                                            marginBottom: '5px',
                                            fontSize: '17px',
                                        }}
                                    >
                                        *
                                    </Typography>
                                </Box>
                            </Tooltip>
                        ) : (
                            <Box
                                display="flex"
                                alignItems="center"
                                height="0px"
                            >
                                <Typography
                                    fontFamily={'Inter'}
                                    fontSize={12}
                                    fontWeight={500}
                                    color={'#000021'}
                                >
                                    {params?.row?.days_left ?? '--'}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )
            },
        },
    ]
    return tableColumns
}

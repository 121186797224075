import { ID, TContactV3 } from 'buddy-api/types'
import ApiMixin from '../ApiMixin'
import ApiRequest from 'buddy-api/ApiRequest'

export type ExpiryDateApiProps = {
    leadID: ID
    extended_expiry_date: string | null
}

export default class ExpiryDateAPI extends ApiMixin<ExpiryDateApiProps> {
    public setExpiryDate = async (
        account_id: ID,
        date: string,
        signal?: AbortSignal | null
    ): Promise<{ body: TContactV3; status: number }> => {
        const url = `/leads/${account_id}/extend_expiry`
        const result = await ApiRequest.patch<TContactV3>(url, signal, {
            extended_expiry_date: date,
        })
        return { body: result.body, status: result.response.status }
    }
}

/**
 * @file CallSessionProvider.tsx
 * @description This file contains the implementation of the CallSessionProvider component and the useCallSession hook.
 * @description It provides a context for managing and displaying call session data on callsession page.
 */

import { BuddyAPIClient } from 'api'
import { ID, IAccount, IContactV2, TContactV3 } from 'buddy-api'
import update, { Spec } from 'immutability-helper'
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react'
import { useParams } from 'react-router'
import { useSnackBarAlert } from './SnackBarAlertProvider'
import {
    ICallOption,
    ICallSession,
    ICallSessionOutcomeV2,
    ICallStatus,
    ICallStatusOptionMapping,
    INsOppData,
} from 'buddy-api/types/callstatus'
import {
    IPreferredVendors,
    IReportingCategories,
} from 'buddy-api/types/talktrack'
import { DateTime } from 'luxon'
import formatters from 'utils/formatters'
import { INsOppFormMapping } from 'buddy-api/api/NSDataAPI'
import { omitBy, set } from 'lodash'
import { shouldOmit } from 'utils/FieldFormatter'
import { TAgents } from 'buddy-api/api/AccountAPI'
import { TransactionHistoryType } from 'components/callsession/CustomerTransactionHistoryDataGrid'
import { TContact_billing } from 'buddy-api/api/TransactionHistoryAPI'
import { TFormatedReasonCode } from 'buddy-api/types/reasonCode'

import _ from 'lodash'

export enum CallStatusEnum {
    SELLER_REJECTED = 'Seller Rejected',
    CONTACT_ATTEMPT = 'Contact Attempt',
    CONTACT_SUCCESSFUL = 'Contact Successful',
    REJECT_ACCOUNT = 'Reject Account',
}

export enum CallStatusOptionEnum {
    OPPORTUNITY = 'Opportunity',
    IN_PROGRESS = 'In Progress',
    CUSTOMER_REJECTED = 'Customer Rejected',
    FOLLOW_UP = 'Follow Up',
    SELLER_REJECTED = 'Seller Rejected',
}

export enum CallStatusSecondaryOptionEnum {
    UNABLE_TO_CONTACT = 'Unable to Contact',
    WRONG_ACCOUNT = 'Wrong Acc Assigned​',
    PRODUCTS_NOT_NEEDED = 'Products Not Needed​',
    HAS_OTHER_VENDOR = 'Has Other Vendor',
    NOT_DECISION_MAKER = 'Not Decision Maker',
    UNSUITABLE_OPPORTUNITY = 'Unsuitable Opportunities',
    BUDGET = 'Budget',
    WRONG_TIMING = 'Wrong Timing',
    OTHER = 'Other',
    NOT_NEEDED = 'Not Needed​',
    EXISTING_PROJECT = 'Existing Project',
    RECENTLY_CONTACTED = 'Recently Contacted',
    LEFT_VOICEMAIL = 'Left Voicemail',
    LEFT_EMAIL = 'Left Email',
    LEFT_VOICEMAIL_AND_EMAIL = 'Left Voicemail and Email',
    UNABLE_TO_LEAVE_VM = 'Unable to Leave VM or Email',
    CONTACT_AT_DIFFERENT_COMPANY = 'Contact at Different Company',
    WRONG_INFO = 'Wrong Info',
    UNSUITABLE_ACCOUNT = 'Unsuitable Account',
}

export enum Status {
    NEW = 'New',
    OPEN = 'Open',
    IN_PROGRESS = 'In Progress',
    OPPORTUNITY = 'Opportunity',
    CUSTOMER_REJECTED = 'Customer Rejected',
    SELLER_REJECTED = 'Seller Rejected',
}

export enum AccountStatus {
    NEW = 'New',
    OPEN = 'Open',
    IN_PROGRESS = 'In Progress',
    OPPORTUNITY = 'Closed',
    REJECTED = 'Rejected',
}

export type THistoryFilterValues = {
    [key: string]: string[]
}
export type TCallSessionFilterOptions = {
    [key: string]: string[]
}

type ICallSessionContext = {
    account?: IAccount
    callStatus: ICallStatus[]
    sessionOutcomes: ICallOption[]
    reasonsV2: ICallOption[]
    callSessionV2: ICallSession
    accountContacts: TContactV3[]
    activeContact?: TContactV3
    followUpDate: DateTime | null
    expiryDate: DateTime | null
    reportingCategories: IReportingCategories[]
    isSaving: boolean
    loading: boolean
    saveSessionDisabled: boolean
    nsOppFormMapping: INsOppFormMapping[]
    nsOppData: INsOppData
    filterOptions: TCallSessionFilterOptions
    filterState: THistoryFilterValues
    agents: TAgents[]
    emailCampaignOutreach: string | null
    contactBilling: TContact_billing[]
    callStatusOptions: TFormatedReasonCode[]
    setTransactionHistoryType: Dispatch<SetStateAction<TransactionHistoryType>>
    handleFilterResetCallback: () => void
    setEmailCampaignOutreachCallback: (updatedData: string | null) => void
    saveCallSession: () => Promise<void>
    setFollowUpDateCallback: () => void
    setExpiryDateCallback: () => void
    deleteFollowUpDateCallback: (
        contact_id: string,
        follow_up_date: string
    ) => Promise<void>
    updateFollowUpDateCallback: (
        contactId: string,
        oldFollowUpDate: string,
        newFollowUpDate: string
    ) => void
    updateSelections: (updates: Spec<ICallSession, never>) => void
    fetchTalkTrack: (itemID: ID) => Promise<IReportingCategories | null>
    setLoading: (value: boolean) => void
    setFollowUpDate: Dispatch<SetStateAction<DateTime | null>>
    setExpiryDate: Dispatch<SetStateAction<DateTime | null>>
    fetchPreferredVendors: (arr: string) => Promise<IPreferredVendors[]>
    updateNsOppForm: (updates: Spec<INsOppData, never>) => void
    fetchInteractionHistory: (
        pageSize: number,
        page: number,
        sortOrder: string,
        accountID: number,
        queryOptions: Record<string, string>
    ) => Promise<any>
    fetchTransactionHistory: (
        historyType: string,
        pageSize: number,
        page: number,
        sortOrder: string,
        accountID: number,
        queryOptions: Record<string, string>
    ) => Promise<any>
    handleFilterStateChangeCallback: (
        columnField: keyof TCallSessionFilterOptions,
        filters: string[]
    ) => void
    handleContactChangeCallback: (value: TContactV3) => void
    setContactStar: (contact_id: string) => Promise<unknown>
    handleSaveNoteCallback: () => Promise<void>
}

const CallSessionContext = React.createContext<ICallSessionContext>(
    {} as ICallSessionContext
)

const CallSessionProvider: React.FC = ({ children }) => {
    const [callStatus, setCallStatus] = useState<ICallStatus[]>([])
    const [saveSessionDisabled, setSaveSessionDisabled] =
        useState<boolean>(false)
    const [sessionOutcomes, setSessionOutcomes] = useState<ICallOption[]>([])
    const [reasonsV2, setReasonsV2] = useState<ICallOption[]>([])
    const [callSessionV2, setCallSessionV2] = useState<ICallSession>({})
    const [reportingCategories, setReportingCategories] = useState<
        IReportingCategories[]
    >([])
    const [account, setAccount] = useState<IAccount>()
    const [activeContact, setActiveContact] = useState<TContactV3>()
    const [accountContacts, setAccountContacts] = useState<TContactV3[]>([])
    const [followUpDate, setFollowUpDate] = useState<DateTime | null>(null)
    const [expiryDate, setExpiryDate] = useState<DateTime | null>(null)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [nsOppFormMapping, setNsOppFormMapping] = useState<
        INsOppFormMapping[]
    >([])
    const [agents, setAgents] = useState<TAgents[]>([])
    const [nsOppData, setNsOppData] = useState<INsOppData>({
        title: null,
        custbody_opportunity_category: null,
        custbody_hardware_category: null,
        custbody_opp_manufacturer1: null,
        custbody_opp_next_steps: null,
        expectedclosedate: null,
        custbody_opp_expected_ship_date: null,
        custbody_opp_amount: null,
        custbody_opp_expected_margin: null,
        custbody_consumables_amount: null,
        custbody_opp_consumable_expectedmargin: null,
        custbody_prof_svcs_amount: null,
        custbody_opp_prof_svcs_expected_margin: null,
    })
    const [filterOptions, setFilterOptions] =
        useState<TCallSessionFilterOptions>({
            // transaction history
            item: [],
            manufacturer: [],
            sku: [],
            doc_id: [],
            contact_name: [],
            status: [],

            // interaction history
            reason_code: [],
            added_by: [],
            netsuite_flag: ['Netsuite', 'Buddy'],
        })
    const [filterState, setFilterState] = useState<THistoryFilterValues>({
        // transaction history
        order_date: [],
        item: [],
        manufacturer: [],
        sku: [],
        doc_id: [],
        contact_name: [],
        status: [],
        // interaction history
        date: [],
        reason_code: [],
        added_by: [],
        netsuite_flag: [],
    })

    const handleFilterResetCallback = useCallback(() => {
        setFilterState((prevState) => ({
            ...prevState,
            order_date: [],
            item: [],
            manufacturer: [],
            sku: [],
            doc_id: [],
            contact_name: [],
            status: [],
            date: [],
            reason_code: [],
            added_by: [],
        }))
    }, [])

    const handleFilterStateChangeCallback = useCallback(
        async (
            columnField: keyof TCallSessionFilterOptions,
            filters: string[]
        ) => {
            setFilterState((prevState) => ({
                ...prevState,
                [columnField]: filters,
            }))
        },
        []
    )
    const [contactBilling, setContactBilling] = useState<TContact_billing[]>([])
    const [callStatusOptions, setCallStatusOptions] = useState<
        TFormatedReasonCode[]
    >([])
    const params = useParams()
    const { showAlert } = useSnackBarAlert()
    const [transactionHistoryType, setTransactionHistoryType] =
        useState<TransactionHistoryType>('Sales Order')

    const [emailCampaignOutreach, setEmailCampaignOutreach] = useState<
        string | null
    >(null)

    const setEmailCampaignOutreachCallback = useCallback(
        (updatedData: string | null) => {
            setEmailCampaignOutreach(updatedData)
        },
        []
    )

    const handleContactChangeCallback = useCallback((item) => {
        setActiveContact(item)
    }, [])

    const fetchInteractionHistory = useCallback(
        async (
            pageSize: number,
            page: number,
            sortOrder: string,
            accountID: number,
            queryOptions: Record<string, string>
        ) => {
            let params = {
                ordering: sortOrder,
                limit: pageSize,
                offset: pageSize * page,
                ...queryOptions,
                account_id: accountID,
            }
            const cleanParams = omitBy(params, shouldOmit)

            const response = await BuddyAPIClient.InteractionHistory.list(
                cleanParams
            )
            return response
        },
        []
    )
    const fetchTransactionHistory = useCallback(
        async (
            historyType: string,
            pageSize: number,
            page: number,
            sortOrder: string,
            accountID: number,
            queryOptions: Record<string, string>
        ) => {
            let params = {
                type: historyType,
                ordering: sortOrder,
                limit: pageSize,
                offset: pageSize * page,
                ...queryOptions,
                account_id: accountID,
            }
            const cleanParams = omitBy(params, shouldOmit)
            const response = await BuddyAPIClient.TransactionHistory.list(
                cleanParams
            )
            return response
        },
        []
    )

    const fetchCallStatus = useCallback(async () => {
        const response = await BuddyAPIClient.callStatus.list()
        setCallStatus(response.results)
    }, [])

    const updateNsOppForm = useCallback((updates: Spec<INsOppData, never>) => {
        setNsOppData((c) => update(c, updates))
    }, [])

    const fetchAgents = useCallback(async () => {
        if (account?.account_id) {
            const response = await BuddyAPIClient.AccountDetails.agents(
                account?.account_id
            )
            setAgents(response)
            response.forEach((item) => {
                setFilterOptions((prev) => {
                    return {
                        ...prev,
                        added_by: [...prev.added_by, item.full_name ?? ''],
                    }
                })
            })
        }
    }, [account?.account_id])

    const fetchTransactionHistoryFilters = useCallback(async () => {
        if (account?.account_id) {
            const response =
                await BuddyAPIClient.TransactionHistory.transaction_column_filters(
                    account?.account_id,
                    transactionHistoryType
                )
            response.contact_billing.push({
                contact: 'NA',
                contact_id: 'NA',
                full_name: 'N/A',
            })
            setContactBilling(response.contact_billing)

            setFilterOptions((prev) => {
                return {
                    ...prev,
                    item: response.item_category,
                    manufacturer: response.item_manufacturer,
                    sku: response.item_name,
                    doc_id: response.document_number,
                    contact_name: response.contact_billing
                        .map((item) => item.full_name)
                        .filter((item): item is string => item !== null),
                    status: response.status,
                }
            })
        }
    }, [account?.account_id, transactionHistoryType])

    const fetchCallStatusOptions = useCallback(async () => {
        const response = await BuddyAPIClient.callStatusOptions.list()
        const formattedResponse: { id: string | number; code: string }[] =
            response.results.map((item) => {
                const code = [
                    item.call_status?.name,
                    item.call_status_option?.name,
                    item.call_status_secondary_option?.name,
                ]
                    .filter(Boolean)
                    .join(',')

                return {
                    id: item.id,
                    code: code,
                }
            })

        formattedResponse.push({
            id: 'NA',
            code: 'N/A',
        })
        setCallStatusOptions(formattedResponse)
        setFilterOptions((prev) => {
            return {
                ...prev,
                reason_code: formattedResponse.map((item) => item.code),
            }
        })
    }, [account?.account_id])

    useEffect(() => {
        fetchAgents()
    }, [account?.account_id])
    useEffect(() => {
        fetchTransactionHistoryFilters()
    }, [account?.account_id, transactionHistoryType])

    useEffect(() => {
        fetchNsDataCallback()
        fetchCallStatusOptions()
    }, [])

    const fetchNsDataCallback = useCallback(async () => {
        const response = await BuddyAPIClient.NSData.list()
        setNsOppFormMapping(response.results)
    }, [setNsOppFormMapping])

    useEffect(() => {
        if (
            activeContact?.status == AccountStatus.OPPORTUNITY ||
            activeContact?.status == AccountStatus.REJECTED
        ) {
            setSaveSessionDisabled(true)
            return
        }
        setSaveSessionDisabled(false)
    }, [activeContact])

    const fetchReportingCategories = useCallback(async () => {
        const response = await BuddyAPIClient.TalkTrack.list({
            limit: 320,
            offset: 0,
        })
        setReportingCategories(response.results)
    }, [])

    const fetchPreferredVendors = useCallback(
        async (arr: string) => {
            const res: IPreferredVendors[] =
                await BuddyAPIClient.PreferredVendors.getPreferredVendors({
                    account_id: account?.account_id,
                    item_ids: arr,
                })
            return res
        },
        [account?.account_id]
    )

    const fetchTalkTrack = useCallback(async (itemID: ID) => {
        if (itemID) {
            const response = await BuddyAPIClient.TalkTrack.retrieve(itemID)
            return response
        }
        return null
    }, [])

    const fetchAccountContacts = useCallback(async (accountID: string) => {
        const response = await BuddyAPIClient.Contacts.list({
            account_id: accountID,
        })

        const filteredContacts = response.results.filter(
            (contact) => contact.priority_flag
        )
        let filtersContactSort = []
        filtersContactSort = filteredContacts.sort((a: any, b: any) => {
            const dateA: any = a.date_of_priority_change
                ? new Date(a.date_of_priority_change)
                : null
            const dateB: any = b.date_of_priority_change
                ? new Date(b.date_of_priority_change)
                : null
            if (dateA === null && dateB === null) {
                return 0
            }
            if (dateA === null) {
                return 1
            }
            if (dateB === null) {
                return -1
            }
            return new Date(dateB).getTime() - new Date(dateA).getTime()
        })

        if (filtersContactSort.length > 0) {
            setActiveContact(filtersContactSort[0])
            setEmailCampaignOutreachCallback(
                filtersContactSort[0].email_campaign
            )
        }

        filtersContactSort.push({
            contact_id: 'notListed',
            last_interaction: null,
            created_at: null,
            date_of_priority_change: null,
            modified_at: null,
            full_name: 'Other Contact - Not Listed',
            first_name: null,
            middle_name: null,
            last_name: null,
            primary_email: null,
            secondary_email: null,
            phone: null,
            job_title: null,
            subscription_status: null,
            linkedin_url: null,
            last_contacted_date: null,
            netsuite_flag: false,
            priority_flag: true,
            star: false,
            company_phone: null,
            seniority: null,
            departments: null,
            city: null,
            state: null,
            country: null,
            email_campaign: null,
            follow_ups: null,
            follow_up_date: null,
            account: null,
            action: null,
            status: null,
            open_qoute_flag: false,
            new_location_flag: false,
        })
        setAccountContacts(filtersContactSort)
    }, [])

    const setContactStar = useCallback((contact_id) => {
        const response = BuddyAPIClient.Contacts.star(contact_id)
        return response
    }, [])

    const fetchAccountDetails = useCallback(
        async (accountID: ID) => {
            const response = await BuddyAPIClient.AccountDetails.retrieve(
                accountID
            )
            response.filtered_leads?.sort((a, b) => b.propensity - a.propensity)
            updateSelections({
                account: {
                    $set: response.account_id,
                },
                lead: {
                    $set: response.filtered_leads?.[0]?.lead_id,
                },
            })
            if (response.follow_up_date)
                setFollowUpDate(
                    formatters.formatDate(
                        response.follow_up_date,
                        'utc',
                        'America/Chicago'
                    ).dateTime
                )
            if (response.filtered_leads?.[0]?.expiry_date) {
                setExpiryDate(
                    formatters.formatDate(
                        response.filtered_leads?.[0]?.expiry_date,
                        'utc',
                        'America/Chicago'
                    ).dateTime
                )
            }
            setAccount(response)
        },
        [account]
    )

    React.useEffect(() => {
        const loadData = async () => {
            if (params.customer_id) {
                setLoading(true)
                try {
                    const accountID = encodeURIComponent(params.customer_id)
                    await Promise.all([
                        fetchCallStatus(),
                        fetchReportingCategories(),
                        fetchAccountDetails(accountID),
                        fetchAccountContacts(accountID),
                    ])
                } catch (err) {
                    console.error(err)
                    showAlert('error', 'Error fetching account details.', 6000)
                } finally {
                    setLoading(false)
                }
            }
        }

        loadData()
    }, [params.customer_id])

    const updateSelections = useCallback(
        (updates: Spec<ICallSession, never>) => {
            setCallSessionV2((c) => update(c, updates))
        },
        []
    )

    //set expiry date
    const setExpiryDateCallback = useCallback(async () => {
        try {
            const formattedDate = localStorage.getItem('expiryDate')
            if (formattedDate === null || formattedDate === undefined) {
                showAlert('error', 'Follow Up Date cannot be null.', 6000)
                return
            }
            if (account?.account_id) {
                const { body, status } =
                    await BuddyAPIClient.ExtendExpiryDate.setExpiryDate(
                        account?.account_id,
                        formattedDate
                    )

                if (status == 200) {
                    showAlert(
                        'success',
                        'Expiry Date extended successfully.',
                        3000
                    )
                    localStorage.removeItem('expiryDate')

                    if (params.account_id) {
                        const accountID = encodeURIComponent(params.account_id)
                        await fetchAccountContacts(accountID)
                        await fetchAccountDetails(accountID)
                    }
                }
            } else {
                showAlert('error', 'Account ID cannot be null', 6000)
            }
        } catch (error) {
            showAlert('error', 'Expiry date cannot be null', 6000)
        }
    }, [expiryDate, activeContact, params, account])

    // set follow up date
    const setFollowUpDateCallback = useCallback(async () => {
        try {
            const formattedDate = followUpDate
                ? formatters.formatDate(
                      followUpDate.toISO(),
                      'America/Chicago',
                      'utc'
                  ).isoDate
                : null
            if (
                activeContact?.status == AccountStatus.OPPORTUNITY ||
                activeContact?.status == AccountStatus.REJECTED
            ) {
                showAlert(
                    'info',
                    'Cannot add Follow Up Date for Closed or Rejected Contacts.',
                    6000
                )
                return
            }
            if (formattedDate === null || formattedDate === undefined) {
                showAlert('error', 'Follow Up Date cannot be null.', 6000)
                return
            }

            if (activeContact?.contact_id && account?.account_id) {
                const { body, status } =
                    await BuddyAPIClient.Contacts.setFollowUpDate(
                        activeContact.contact_id,
                        account?.account_id,
                        formattedDate.split('T')[0]
                    )

                if (status == 200) {
                    showAlert(
                        'success',
                        'Follow Up Date added successfully.',
                        6000
                    )
                    setFollowUpDate(null)

                    if (params.customer_id) {
                        const accountID = encodeURIComponent(params.customer_id)
                        await fetchAccountContacts(accountID)
                        await fetchAccountDetails(accountID)
                    }
                }
            } else {
                showAlert('error', 'Contact ID cannot be null', 6000)
            }
        } catch (error) {
            showAlert(
                'error',
                'The follow-up for the contact already exists for the given date.',
                6000
            )
        }
    }, [followUpDate, activeContact, params, account])

    // delete follow up date
    const deleteFollowUpDateCallback = useCallback(
        async (contactId, deleteFollowupDate) => {
            try {
                const formattedDate = deleteFollowupDate
                    ? formatters.formatDate(
                          DateTime.fromFormat(
                              deleteFollowupDate,
                              'M/d/yyyy'
                          ).toISO(),
                          'America/Chicago',
                          'utc'
                      ).isoDate
                    : null

                if (formattedDate === null || formattedDate === undefined) {
                    showAlert('error', 'Date cannot be null.', 6000)
                    return
                }

                if (contactId && formattedDate) {
                    const { body, status } =
                        await BuddyAPIClient.Contacts.deleteFollowUpDate(
                            contactId,
                            formattedDate.split('T')[0]
                        )

                    if (status == 200) {
                        setFollowUpDate(null)
                        showAlert(
                            'success',
                            'Follow Up Date deleted successfully.',
                            6000
                        )

                        if (params.customer_id) {
                            const accountID = encodeURIComponent(
                                params.customer_id
                            )
                            await fetchAccountContacts(accountID)
                            await fetchAccountDetails(accountID)
                        }
                    }
                } else {
                    showAlert('error', 'Contact ID cannot be null', 6000)
                }
            } catch (error) {
                showAlert('error', 'Error deleting follow up date.', 6000)
            }
        },
        [params]
    )

    // update follow up date
    const updateFollowUpDateCallback = useCallback(
        async (contactId, oldFollowUpDate, newFollowUpDate) => {
            try {
                const oldDate = DateTime.fromFormat(
                    oldFollowUpDate,
                    'M/d/yyyy'
                ).toISO()

                if (oldDate === null || oldDate === undefined) {
                    showAlert('error', 'Invalid Date', 6000)
                    return
                }

                if (activeContact?.contact_id && account?.account_id) {
                    const { body, status } =
                        await BuddyAPIClient.Contacts.updateFollowUpDate(
                            contactId,
                            account?.account_id,
                            oldDate.split('T')[0],
                            newFollowUpDate
                        )

                    if (status == 200) {
                        showAlert(
                            'success',
                            'Follow Up Date updated successfully.',
                            6000
                        )
                        if (params.customer_id) {
                            const accountID = encodeURIComponent(
                                params.customer_id
                            )
                            await fetchAccountContacts(accountID)
                            await fetchAccountDetails(accountID)
                        }
                    }
                } else {
                    showAlert('error', 'Contact ID cannot be null', 6000)
                }
            } catch (error) {
                showAlert('error', 'Error updating follow up date.', 6000)
            }
        },
        [activeContact, params, account]
    )

    const getSessionOutcomes = useCallback(async () => {
        const selectedCallStatusObject = callStatus.find(
            (element) => element.id == callSessionV2.action?.id
        )
        const sessionNamesList =
            selectedCallStatusObject?.call_status_option_mapping
                .map(
                    (item) =>
                        item.call_status_option !== null &&
                        item.call_status_option
                )
                .filter(
                    (item): item is ICallOption =>
                        item !== false && item !== undefined
                )

        const uniqueSessionListById = Array.from(
            new Map(
                sessionNamesList?.map((item: ICallOption) => [item.id, item])
            ).values()
        )
        uniqueSessionListById.sort((a, b) => (a?.order || 0) - (b?.order || 0))
        return uniqueSessionListById
    }, [callSessionV2.action, callStatus])

    const getRadioOptions = useCallback(
        async (
            selectedCallStatus: ICallOption | undefined | null,
            selectedSessionOutcome: ICallOption | undefined | null
        ) => {
            const selectedCallStatusObject = callStatus.find(
                (element) => element.id == selectedCallStatus?.id
            )
            let radioNamesList: ICallOption[] = []
            if (selectedCallStatusObject) {
                const check =
                    selectedCallStatusObject.call_status_option_mapping.find(
                        (element) =>
                            element.call_status_option?.id ==
                            selectedSessionOutcome?.id
                    )

                if (check?.call_status?.id == selectedCallStatus?.id) {
                    radioNamesList =
                        selectedCallStatusObject.call_status_option_mapping
                            .filter(
                                (item) =>
                                    item.call_status_option?.id ==
                                    selectedSessionOutcome?.id
                            )
                            .map((item) => item.call_status_secondary_option)
                            .filter(
                                (item): item is ICallOption =>
                                    item !== null && item !== undefined
                            )
                } else if (selectedCallStatus?.id !== '') {
                    radioNamesList =
                        selectedCallStatusObject.call_status_option_mapping
                            .map((item) =>
                                item.call_status_option === null
                                    ? item.call_status_secondary_option
                                    : null
                            )
                            .filter(
                                (item): item is ICallOption => item !== null
                            )
                }
            }
            const uniqueRadioListById = Array.from(
                new Map(radioNamesList.map((item) => [item.id, item])).values()
            )

            uniqueRadioListById.sort(
                (a, b) => (a?.order || 0) - (b?.order || 0)
            )
            return uniqueRadioListById
        },
        [callSessionV2.action, callStatus, callSessionV2.sessionOutcome]
    )

    useEffect(() => {
        if (callSessionV2.action) {
            getSessionOutcomes().then((result) => setSessionOutcomes(result))
        }
    }, [callSessionV2.action])

    useEffect(() => {
        if (callSessionV2.action || callSessionV2.sessionOutcome) {
            getRadioOptions(
                callSessionV2.action,
                callSessionV2.sessionOutcome
            ).then((result) => setReasonsV2(result))
        }
    }, [callSessionV2.action, callSessionV2.sessionOutcome])

    const getActionID = useCallback(async () => {
        const callStatusObject = callStatus.find(
            (element) => element.id == callSessionV2.action?.id
        )
        return callStatusObject?.call_status_option_mapping.find((element) => {
            const matchesSessionOutcome =
                callSessionV2.sessionOutcome?.id === '' ||
                element.call_status_option?.id ==
                    callSessionV2.sessionOutcome?.id
            const matchesReason =
                callSessionV2.reason?.id == '' ||
                element.call_status_secondary_option?.id ==
                    callSessionV2.reason?.id
            return matchesSessionOutcome && matchesReason
        })?.id
    }, [callSessionV2])

    const omitInvalidValues = async (obj: {
        [key: string]: any
    }): Promise<{
        cleanedObj: { [key: string]: any }
        omittedKeys: string[]
    }> => {
        let omittedKeys: string[] = []

        const omitRecursively = (obj: any, path: string = ''): any => {
            return _.transform(
                obj,
                (result: any, value: any, key: string | number | symbol) => {
                    const fullPath = path
                        ? `${path}.${String(key)}`
                        : String(key)
                    if (value === null || value === '' || value === undefined) {
                        omittedKeys.push(fullPath)
                    } else if (_.isObject(value) && !_.isArray(value)) {
                        const nested = omitRecursively(value, fullPath)
                        if (!_.isEmpty(nested)) {
                            result[String(key)] = nested
                        } else {
                            omittedKeys.push(fullPath)
                        }
                    } else {
                        result[String(key)] = value
                    }
                },
                {}
            )
        }
        const cleanedObj = omitRecursively(obj)
        return {
            cleanedObj,
            omittedKeys,
        }
    }

    const saveCallSession = useCallback(async () => {
        if (
            (activeContact?.status == AccountStatus.OPPORTUNITY &&
                account?.status == AccountStatus.OPPORTUNITY) ||
            account?.status == AccountStatus.REJECTED
        ) {
            showAlert(
                'info',
                'Cannot save call session for Closed or Rejected Accounts.',
                6000
            )
            return
        }
        if (
            callSessionV2.action?.name !== CallStatusEnum.REJECT_ACCOUNT &&
            (activeContact?.status == AccountStatus.OPPORTUNITY ||
                activeContact?.status == AccountStatus.REJECTED)
        ) {
            showAlert(
                'info',
                'Cannot save call session for Closed or Rejected Contacts.',
                6000
            )
            return
        }
        const requestBody = {
            account: callSessionV2.account,
            lead: callSessionV2.lead,
            action: await getActionID(),
            ns_opp_data: nsOppData,
            title: callSessionV2.title,
            notes: callSessionV2.notes,
            ...(activeContact?.contact_id !== 'notListed' &&
                callSessionV2.action?.name !==
                    CallStatusEnum.REJECT_ACCOUNT && {
                    contact: activeContact?.contact_id,
                }),
        }

        const { cleanedObj, omittedKeys } = await omitInvalidValues(requestBody)

        if (
            activeContact?.contact_id !== 'notListed' &&
            callSessionV2.action?.name !== CallStatusEnum.REJECT_ACCOUNT &&
            (omittedKeys.includes('contact') ||
                omittedKeys.includes('account') ||
                omittedKeys.includes('lead') ||
                omittedKeys.includes('action'))
        ) {
            const missingKeys = omittedKeys
                .filter(
                    (key) =>
                        key === 'account' ||
                        key === 'lead' ||
                        key === 'action' ||
                        key === 'contact'
                )
                .join(', ')
                .replace(
                    /action/g,
                    `${callSessionV2.action?.name ? '' : 'action'}
                    ${
                        sessionOutcomes.length > 0
                            ? callSessionV2.sessionOutcome?.name
                                ? ''
                                : 'session outcome'
                            : ''
                    }
                    ${
                        reasonsV2.length > 0
                            ? callSessionV2.reason?.name
                                ? ''
                                : 'reason'
                            : ''
                    }
                    `
                )

            showAlert(
                'error',
                'Please input all required fields. Missing required fields: ' +
                    missingKeys,
                6000
            )
            return
        }

        if (
            (activeContact?.contact_id === 'notListed' ||
                callSessionV2.action?.name === CallStatusEnum.REJECT_ACCOUNT) &&
            (omittedKeys.includes('account') ||
                omittedKeys.includes('lead') ||
                omittedKeys.includes('action'))
        ) {
            const missingKeys = omittedKeys
                .filter(
                    (key) =>
                        key === 'account' || key === 'lead' || key === 'action'
                )
                .join(', ')
                .replace(
                    /action/g,
                    `${callSessionV2.action?.name ? '' : 'action'}
                    ${
                        sessionOutcomes.length > 0
                            ? callSessionV2.sessionOutcome?.name
                                ? ''
                                : 'session outcome'
                            : ''
                    }
                    ${
                        reasonsV2.length > 0
                            ? callSessionV2.reason?.name
                                ? ''
                                : 'reason'
                            : ''
                    }
                    `
                )

            showAlert(
                'error',
                'Please input all required fields. Missing required fields: ' +
                    missingKeys,
                6000
            )
            return
        }
        if (
            callSessionV2.sessionOutcome?.name == 'Opportunity' &&
            omittedKeys.some((item) => item.includes('ns_opp_data'))
        ) {
            const missingKeys = omittedKeys
                .filter((key) => key.includes('ns_opp_data'))
                .join(', ')
                .replace(/ns_opp_data./g, '')
                .replace(/custbody_/g, '')

            showAlert(
                'error',
                'Please input all Opportunity fields. Missing required fields: ' +
                    missingKeys,
                6000
            )
            return
        }

        try {
            showAlert('info', 'Saving the session.', 800)
            await BuddyAPIClient.InteractionHistory.create(
                cleanedObj as ICallSessionOutcomeV2
            )
            showAlert('success', 'Call session saved successfully.', 6000)
        } catch {
            showAlert('error', 'Error saving call session.', 6000)
        } finally {
            setIsSaving(false)
            setCallSessionV2({})
            setSessionOutcomes([])
            setReasonsV2([])
            setNsOppData({
                title: null,
                custbody_opportunity_category: null,
                custbody_hardware_category: null,
                custbody_opp_manufacturer1: null,
                custbody_opp_next_steps: null,
                expectedclosedate: null,
                custbody_opp_expected_ship_date: null,
                custbody_opp_amount: null,
                custbody_opp_expected_margin: null,
                custbody_consumables_amount: null,
                custbody_opp_consumable_expectedmargin: null,
                custbody_prof_svcs_amount: null,
                custbody_opp_prof_svcs_expected_margin: null,
            })
            if (params.customer_id) {
                setLoading(true)
                try {
                    const accountID = encodeURIComponent(params.customer_id)
                    await Promise.all([
                        fetchCallStatus(),
                        fetchReportingCategories(),
                        fetchAccountDetails(accountID),
                        fetchAccountContacts(accountID),
                    ])
                } catch (err) {
                    console.error(err)
                    showAlert('error', 'Error fetching account details.', 6000)
                } finally {
                    setLoading(false)
                }
            }
        }
    }, [
        callSessionV2,
        nsOppData,
        activeContact,
        callStatus,
        sessionOutcomes,
        reasonsV2,
        account,
    ])

    const handleSaveNoteCallback = useCallback(async () => {
        if (
            account?.status == AccountStatus.OPPORTUNITY ||
            account?.status == AccountStatus.REJECTED
        ) {
            showAlert(
                'info',
                'Cannot save note for Closed or Rejected Accounts.',
                6000
            )
            return
        }
        let requestBody: ICallSessionOutcomeV2 = {
            account: account?.account_id,
            lead: callSessionV2.lead,
            ...(activeContact?.contact_id !== 'notListed' &&
                callSessionV2.action?.name !==
                    CallStatusEnum.REJECT_ACCOUNT && {
                    contact: activeContact?.contact_id,
                }),
            title: callSessionV2.title,
            notes: callSessionV2.notes,
        }
        try {
            const response = await BuddyAPIClient.InteractionHistory.create(
                requestBody
            )
            showAlert('success', 'Note saved successfully.', 6000)
        } catch (e) {
            showAlert('error', 'Error saving note.', 6000)
        } finally {
            setCallSessionV2({})
            setSessionOutcomes([])
            setReasonsV2([])
        }
    }, [callSessionV2, activeContact, account])

    const contextValue = React.useMemo(() => {
        return {
            account,
            callStatus,
            sessionOutcomes,
            reasonsV2,
            callSessionV2,
            followUpDate,
            expiryDate,
            reportingCategories,
            isSaving,
            loading,
            saveSessionDisabled,
            nsOppFormMapping,
            nsOppData,
            filterOptions,
            filterState,
            agents,
            emailCampaignOutreach,
            contactBilling,
            callStatusOptions,
            activeContact,
            accountContacts,
            handleFilterResetCallback,
            updateNsOppForm,
            setFollowUpDate,
            setExpiryDate,
            setLoading,
            setAccount,
            setFollowUpDateCallback,
            setExpiryDateCallback,
            saveCallSession,
            updateSelections,
            fetchTalkTrack,
            fetchPreferredVendors,
            fetchInteractionHistory,
            fetchTransactionHistory,
            handleFilterStateChangeCallback,
            setEmailCampaignOutreachCallback,
            setTransactionHistoryType,
            handleContactChangeCallback,
            setContactStar,
            deleteFollowUpDateCallback,
            updateFollowUpDateCallback,
            handleSaveNoteCallback,
        }
    }, [
        account,
        callStatus,
        sessionOutcomes,
        reasonsV2,
        callSessionV2,
        followUpDate,
        expiryDate,
        reportingCategories,
        loading,
        isSaving,
        saveSessionDisabled,
        nsOppFormMapping,
        nsOppData,
        filterOptions,
        filterState,
        agents,
        emailCampaignOutreach,
        contactBilling,
        callStatusOptions,
        activeContact,
        accountContacts,
        setFollowUpDateCallback,
        setExpiryDateCallback,
        handleFilterResetCallback,
        updateNsOppForm,
        setFollowUpDate,
        setExpiryDate,
        setLoading,
        setAccount,
        setFollowUpDate,
        saveCallSession,
        updateSelections,
        fetchTalkTrack,
        fetchPreferredVendors,
        fetchInteractionHistory,
        fetchTransactionHistory,
        handleFilterStateChangeCallback,
        setEmailCampaignOutreachCallback,
        setTransactionHistoryType,
        handleContactChangeCallback,
        setContactStar,
        deleteFollowUpDateCallback,
        updateFollowUpDateCallback,
        handleSaveNoteCallback,
    ])
    return (
        <CallSessionContext.Provider value={contextValue}>
            {children}
        </CallSessionContext.Provider>
    )
}

function useCallSession() {
    const context = React.useContext(CallSessionContext)
    if (context === undefined) {
        throw new Error(
            'useCallSession must be used within a CallSessionProvider'
        )
    }
    return context
}

export { CallSessionProvider, useCallSession }

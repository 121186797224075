import { BuddyAPIClient } from 'api'
import { StorageKeys } from 'buddy-api'
import { useSnackBarAlert } from 'context/SnackBarAlertProvider'
import { useAuthenticatedUser } from 'context/UserProvider'
import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { loginPageURL } from 'routes/routes'

const LogoutPage: React.FC = () => {
    const { showAlert } = useSnackBarAlert()
    const location = useLocation()
    const { isSessionExpired } = location.state || { isSessionExpired: false }
    const { user, setUser } = useAuthenticatedUser()
    const navigate = useNavigate()

    const removeCookies = async () => {
        Cookies.remove(StorageKeys.TOKEN)
        Cookies.remove(StorageKeys.USER_ID)
        Cookies.remove(StorageKeys.MOCK)
        Cookies.remove(StorageKeys.USER_NAME)
    }

    const logoutAndRemoveCookies = async () => {
        try {
            if (user) {
                const token = Cookies.get(StorageKeys.TOKEN) ?? ''
                if (token !== '') {
                    await BuddyAPIClient.auth.logout()
                }
                removeCookies()
                setUser(null)

                if (isSessionExpired) {
                    showAlert(
                        'error',
                        'Session has been expired. Please Login again.',
                        6000
                    )
                } else {
                    showAlert('info', 'You have been logged out.', 6000)
                }
            }
        } catch (err) {
            removeCookies()
            setUser(null)
            console.error('Error during logout:', err)
        } finally {
            navigate(loginPageURL(), { replace: true })
        }
    }

    useEffect(() => {
        logoutAndRemoveCookies()
    }, [])

    return null
}

export default LogoutPage

/**
 * @file CustomerAttributePanelV2.tsx
 * @description This file contains the implementation of the CustomerAttributePanelV2 component.
 * @description It displays a panel with customer attributes.
 */

import {
    Box,
    CircularProgress,
    IconButton,
    Link,
    SxProps,
    Typography,
    Card,
    CardContent,
    Collapse,
} from '@mui/material'
import React from 'react'
import '../components/Scrollbar.scss'
import Grid from '@mui/material/Grid'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Stack } from '@mui/system'
import { IAccount } from 'buddy-api'
import LaunchIcon from '@mui/icons-material/Launch'
import Divider from '@mui/material/Divider'
import { DetailBox } from 'components/callsession'
import { leadStatus } from 'components/opportunity'
import { useCallSession } from 'context/CallSessionProvider'
import { PreferredVendors } from 'components/callsession/PreferredVendors'
import formatters from 'utils/formatters'
import CustomGoogleMaps from 'components/callsession/CustomGoogleMaps'
import { useShareState } from 'context/ShareStateProvider'
import CustomText from 'components/CustomText'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTime } from 'luxon'

type PanelProps = {
    sx?: SxProps
    customerAttributes: IAccount | undefined
}

export type IAttribute = {
    name: string
    value: string
}

export type IAttributeGroup = {
    name: string
    attributes: IAttribute[]
}

export type CallSessionAtrributesView = {
    name: string
    order: number
}

export const CustomerAttributePanelV2: React.FC<PanelProps> = ({
    customerAttributes,
}) => {
    const { mapsApiKey } = useShareState()
    const {
        loading,
        account,
        expiryDate,
        setExpiryDate,
        setExpiryDateCallback,
    } = useCallSession()
    const [expanded, setExpanded] = React.useState(true)

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const todayDate = DateTime.now().setZone('utc').startOf('day')
    const dateToCheck = DateTime.fromISO(
        customerAttributes?.filtered_leads[0]?.expiry_date ?? '',
        {
            zone: 'utc',
        }
    )
        .setZone('utc')
        .startOf('day')
    const diff = dateToCheck.diff(todayDate, 'days')
    const daysLeft = Math.round(diff.days)

    const handleChangeExpiryDate = (date: any) => {
        setExpiryDate(date)
        const expiryFormatDate = `${date.c.year}/${date.c.month}/${date.c.day}`
        localStorage.setItem('expiryDate', expiryFormatDate)
        setExpiryDateCallback()
    }

    const startDate = new Date()
    const maxDate = new Date(startDate)
    maxDate.setDate(maxDate.getDate() + 90)

    const shouldDisableDates = (date: any) => {
        return date > maxDate
    }

    return (
        <Card
            elevation={0}
            sx={{
                bgcolor: '#FFFFFF',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'visible',
            }}
        >
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: 0,
                    padding: 0,
                    minHeight: '5rem',
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '194px',
                        }}
                    >
                        <CircularProgress size={25} />
                    </Box>
                ) : (
                    <>
                        <Box
                            sx={{
                                flex: 1,
                                margin: 2,
                                padding: 2,
                            }}
                        >
                            <Grid
                                container
                                sx={{ display: 'flex', flexWrap: 'nowrap' }}
                            >
                                <Grid
                                    item
                                    xs={'auto'}
                                    flexShrink={5}
                                    sx={{
                                        borderRight:
                                            '1px solid rgba(188, 188, 188, 0.6)',
                                        maxWidth: '100%',
                                    }}
                                >
                                    <Stack
                                        paddingRight={2}
                                        sx={{
                                            minWidth: '220px',
                                            maxWidth: '280px',
                                            width: '100%',
                                        }}
                                        gap={0.5}
                                    >
                                        <Link
                                            href={
                                                customerAttributes?.website ??
                                                '#'
                                            }
                                            target="_blank"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                color: '#774DFC',
                                                textDecoration: 'none',
                                                textDecorationColor: '#774DFC',
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                    textDecorationColor:
                                                        '#774DFC',
                                                    color: '#774DFC',
                                                },
                                            }}
                                            fontSize={18}
                                            fontWeight={600}
                                            fontFamily="Inter"
                                        >
                                            {customerAttributes?.name}
                                            <LaunchIcon fontSize="small" />
                                        </Link>
                                        <Stack
                                            direction={'row'}
                                            spacing={0.5}
                                            alignItems={'center'}
                                        >
                                            <Link
                                                target="_blank"
                                                href={
                                                    `https://166316.app.netsuite.com/app/common/entity/custjob.nl?id=` +
                                                    customerAttributes?.account_id
                                                }
                                                sx={{
                                                    color: 'black',
                                                    textDecoration: 'none',
                                                    '&:hover': {
                                                        textDecoration:
                                                            'underline',
                                                        textDecorationColor:
                                                            'black',
                                                        color: 'black',
                                                    },
                                                }}
                                                fontSize={12}
                                                fontWeight={600}
                                                fontFamily="Inter"
                                            >
                                                {customerAttributes?.account_id}
                                                <LaunchIcon fontSize="inherit" />
                                            </Link>
                                            <Divider
                                                orientation="vertical"
                                                flexItem
                                            ></Divider>
                                            <Stack
                                                direction="row"
                                                gap={0.5}
                                                flexWrap={'wrap'}
                                                justifyContent={'start'}
                                                alignItems={'start'}
                                            >
                                                <CustomText
                                                    backgroundColor={
                                                        leadStatus[
                                                            customerAttributes?.status.toLowerCase() ??
                                                                ''
                                                        ]?.bgColor
                                                    }
                                                    textColor={
                                                        leadStatus[
                                                            customerAttributes?.status.toLowerCase() ??
                                                                ''
                                                        ]?.color
                                                    }
                                                    displayText={
                                                        customerAttributes?.status ??
                                                        ''
                                                    }
                                                ></CustomText>
                                                {account?.email_flag && (
                                                    <CustomText
                                                        backgroundColor="rgba(119, 77, 252, 1)"
                                                        textColor="rgba(255, 255, 255, 1)"
                                                        displayText="EMAIL"
                                                    ></CustomText>
                                                )}
                                                {account?.inbound && (
                                                    <CustomText
                                                        backgroundColor="rgba(46, 158, 238, 1)"
                                                        textColor="rgba(255, 255, 255, 1)"
                                                        displayText="WEB"
                                                    ></CustomText>
                                                )}
                                                {account?.managed_flag ? (
                                                    <CustomText
                                                        backgroundColor="rgba(56, 127, 114, 1)"
                                                        textColor="rgba(255, 255, 255, 1)"
                                                        displayText="MANAGED"
                                                    ></CustomText>
                                                ) : (
                                                    <CustomText
                                                        backgroundColor="rgb(84,98,111)"
                                                        textColor="rgba(255, 255, 255, 1)"
                                                        displayText="UNMANAGED"
                                                    ></CustomText>
                                                )}
                                                {account?.new_location_flag && (
                                                    <CustomText
                                                        backgroundColor="rgba(56, 176, 183, 1)"
                                                        textColor="rgba(255, 255, 255, 1)"
                                                        displayText="NEW LOCATION"
                                                    ></CustomText>
                                                )}
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid
                                    item
                                    xs={true}
                                    flexGrow={2}
                                    sx={{
                                        borderLeft:
                                            '1px solid rgba(188, 188, 188, 0.6)',
                                        maxWidth: '100%',
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        spacing={5}
                                        paddingLeft={2}
                                    >
                                        <DetailBox
                                            title="Industry"
                                            detail={
                                                customerAttributes?.industry ??
                                                customerAttributes?.industry_ns ??
                                                'N/A'
                                            }
                                        />
                                        <DetailBox
                                            title="Parent Company"
                                            detail={
                                                customerAttributes?.parent ??
                                                'N/A'
                                            }
                                        />
                                        <DetailBox
                                            title="Building Designation"
                                            detail={
                                                customerAttributes?.building_type ??
                                                'N/A'
                                            }
                                        />
                                        <DetailBox
                                            title={'Company Revenue'}
                                            detail={
                                                customerAttributes?.dnb_sales_revenue
                                                    ? formatters.currency(
                                                          customerAttributes?.dnb_sales_revenue,
                                                          {
                                                              currency: 'USD',
                                                              isCompact: true,
                                                              hasNoFractions:
                                                                  false,
                                                          }
                                                      )
                                                    : 'N/A'
                                            }
                                        />
                                        <DetailBox
                                            title="Account GP (Last 12 mo.)"
                                            detail={
                                                customerAttributes?.profit
                                                    ? formatters.currency(
                                                          customerAttributes?.profit,
                                                          {
                                                              currency: 'USD',
                                                              isCompact: true,
                                                              hasNoFractions:
                                                                  false,
                                                          }
                                                      )
                                                    : 'N/A'
                                            }
                                        />
                                        <DetailBox
                                            title="No. of Employees"
                                            detail={
                                                customerAttributes?.dnb_employee_count ??
                                                'N/A'
                                            }
                                        />
                                        <DetailBox
                                            title="Estimate Growth"
                                            detail={
                                                customerAttributes?.estimated_growth ??
                                                'N/A'
                                            }
                                        />
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: 11,
                                                    fontWeight: 500,
                                                    bgcolor:
                                                        Number(daysLeft) < 3
                                                            ? '#F5C2C1'
                                                            : '',
                                                    paddingLeft: '5px',
                                                }}
                                            >
                                                Expiry Date
                                            </Typography>

                                            <DatePicker
                                                sx={{
                                                    '.MuiCalendarPicker-root': {
                                                        padding: '8px',
                                                        paddingBottom: '0px',
                                                    },
                                                }}
                                                value={expiryDate}
                                                onChange={(newValue) => {
                                                    handleChangeExpiryDate(
                                                        newValue
                                                    )
                                                }}
                                                disablePast
                                                slotProps={{
                                                    textField: {
                                                        inputProps: {
                                                            readOnly: true,
                                                        },
                                                    },
                                                }}
                                                shouldDisableDate={
                                                    shouldDisableDates
                                                }
                                            />
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Collapse
                                in={expanded}
                                timeout="auto"
                                unmountOnExit
                            >
                                <Divider sx={{ my: 1 }} flexItem />
                                <Stack direction="row" spacing={2}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            minHeight: '120px',
                                            minWidth: '230px',
                                            maxHeight: '120px',
                                            maxWidth: '230px',
                                        }}
                                    >
                                        {mapsApiKey.length > 0 && (
                                            <CustomGoogleMaps
                                                sx={{
                                                    width: '230px',
                                                    height: '120px',
                                                }}
                                                address={
                                                    customerAttributes?.shipping_address ??
                                                    'N/A'
                                                }
                                                API_KEY={mapsApiKey}
                                            />
                                        )}
                                    </Box>
                                    <Box>
                                        <Stack
                                            direction={'column'}
                                            maxWidth={'175px'}
                                            minWidth={'165px'}
                                        >
                                            <DetailBox
                                                title="Shipping Address"
                                                detail={
                                                    customerAttributes?.shipping_address ??
                                                    'N/A'
                                                }
                                            />
                                            <DetailBox
                                                title="Location"
                                                detail={
                                                    customerAttributes?.state_shipping ??
                                                    'N/A'
                                                }
                                            />
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Typography
                                            fontSize={14}
                                            fontFamily={'Inter'}
                                            fontWeight={500}
                                            color={'#000000'}
                                        >
                                            Customer Summary
                                        </Typography>
                                        <Typography
                                            fontSize={12}
                                            fontFamily={'Inter'}
                                            fontWeight={400}
                                            color={'#000021'}
                                        >
                                            {customerAttributes?.description ??
                                                'N/A'}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Collapse>
                        </Box>

                        <Divider orientation="vertical" flexItem />
                        <PreferredVendors
                            expanded={expanded}
                        ></PreferredVendors>
                    </>
                )}
            </CardContent>
            <IconButton
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 16,
                    transform: 'translateY(45%)',
                    bgcolor: 'background.paper',
                    boxShadow: 3,
                    '&:hover': { bgcolor: '#f4f4f4' },
                    zIndex: '1500',
                    width: 28,
                    height: 28,
                }}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
                <ExpandMoreIcon
                    sx={{
                        transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s',
                    }}
                />
            </IconButton>
        </Card>
    )
}

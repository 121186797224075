import TalkTrackAPI from './TalkTrackAPI'
import PreferredVendorsAPI from './PreferredVendorsAPI'
import FollowUpDateAPI from './FollowUpDateAPI'
import InteractionHistoryAPI from './InteractionHistoryAPI'
import AccountAPI from './AccountAPI'
import AuthAPI from './AuthAPI'
import CallStatusAPI from './CallStatusAPI'
import NSDataAPI from './NSDataAPI'
import TransactionHistoryAPI from './TransactionHistoryAPI'
import CallStatusOptionsAPI from './CallStatusOptionsAPI'
import ContactsAPI from './ContactsAPI'
import ExpiryDateAPI from './ExpiryDateAPI'

type APIClientOptions = {
    baseUrl: string
    getTenant: () => string
    onHttp400?: <T>(body: T) => void
    onHttp401?: <T>(body: T) => void
    onHttp403?: <T>(body: T) => void
    onHttp404?: <T>(body: T) => void
    onHttp500?: <T>(body: T) => void
}

export default class APIClient {
    public static baseUrl: string
    public static getTenant: () => string
    public static onHttp400?: <T>(body: T) => void
    public static onHttp401?: <T>(body: T) => void
    public static onHttp403?: <T>(body: T) => void
    public static onHttp404?: <T>(body: T) => void
    public static onHttp500?: <T>(body: T) => void

    public static configure(options: APIClientOptions) {
        APIClient.baseUrl = options.baseUrl
        APIClient.getTenant = options.getTenant
        APIClient.onHttp400 = options.onHttp400
        APIClient.onHttp401 = options.onHttp401
        APIClient.onHttp403 = options.onHttp403
        APIClient.onHttp404 = options.onHttp404
        APIClient.onHttp500 = options.onHttp500
    }
    public static auth = new AuthAPI()
    public static AccountDetails = new AccountAPI('/accounts')
    public static callStatus = new CallStatusAPI('/callstatus')
    public static callStatusOptions = new CallStatusOptionsAPI(
        '/callstatus-options'
    )
    public static InteractionHistory = new InteractionHistoryAPI(
        '/interaction-history'
    )
    public static TransactionHistory = new TransactionHistoryAPI(
        '/transaction-history'
    )
    public static Contacts = new ContactsAPI('/contacts/interactions')
    public static FollowUpDate = new FollowUpDateAPI('/leads')
    public static ExtendExpiryDate = new ExpiryDateAPI('/leads')
    public static TalkTrack = new TalkTrackAPI('/items')
    public static PreferredVendors = new PreferredVendorsAPI(
        '/transaction-history/vendors'
    )
    public static NSData = new NSDataAPI('/ns-data')
}
